import cn from 'classnames';
import React from 'react';
import {RemoveScroll} from 'react-remove-scroll';

import {AppButton} from '@/components/Button';
import {WELCOME_VIEW_BUTTON_TEXT, WELCOME_VIEW_SUBTITLE} from '@/consts/localization/localizationKeys';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import storageService from '@/services/storage/storageService';
import useGreeting from '@/views/Greeting/useGreeting';

const WelcomeContent = ({isAnimationStarted, onClick, greetingRef, greetingTranslation: {x, y}, isDarkBannerText}) => {
    const {title, description} = useGreeting();
    const localizedStrings = getLocalizedStrings();
    const handleClick = () => {
        storageService.setHasSeenWelcomeScreen();
        onClick();
    };

    return (
        <RemoveScroll>
            <div
                className={cn(
                    'fixed z-[3] flex h-screen w-full flex-col items-center justify-center px-8 text-center',
                    isDarkBannerText ? 'text-primary' : 'text-romance'
                )}
            >
                <h2
                    className={cn(
                        'mb-0 text-28 leading-[40px] tracking-04 transition-[opacity,transform] duration-500',
                        {
                            'opacity-0': isAnimationStarted,
                        }
                    )}
                    ref={greetingRef}
                    style={isAnimationStarted ? {transform: `translate(${-x}px, ${-y}px)`} : {}}
                >
                    {title} {description}
                </h2>
                <div
                    className={cn('flex flex-col items-center transition-opacity duration-300', {
                        'opacity-0': isAnimationStarted,
                    })}
                >
                    <div className='mt-4 font-normal'>{localizedStrings[WELCOME_VIEW_SUBTITLE]}</div>
                    <AppButton primary dark onClick={handleClick} className='mt-4 w-auto font-normal'>
                        {localizedStrings[WELCOME_VIEW_BUTTON_TEXT]}
                    </AppButton>
                </div>
            </div>
        </RemoveScroll>
    );
};

export default WelcomeContent;
