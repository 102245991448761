import cn from 'classnames';
import {useRef} from 'react';

import Banner from '@/components/Banner/Banner';
import {AppButton} from '@/components/Button';
import {VIDEO_PLAY_STATES} from '@/consts/video/videoPlayStates';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {twx} from '@/utils/tailwindUtils';

import HighlightsCardContentContainer from '../components/HighlightsCardContentContainer';

const TYPES = {
    TEXT_BOTTOM: 'textBottom',
    TEXT_TOP: 'textTop',
};

const HighlightItem = (props) => {
    const isMobile = useIsMobileLayout();
    const {
        firstButtonAction,
        firstButtonActionKey,
        banner,
        secondButtonAction,
        secondButtonActionKey,
        type,
        loop,
        isFullscreen,
        onClick,
        onEnded,
        onVideoError,
        isActiveSlide,
        isPaused = false,
        isFullscreenGradient,
        onLoadedMetadata,
        videoRef,
        onVideoCanPlay,
        onVideoWaiting,
    } = props;
    const {titleKey, subTitleKey, descriptionKey, imagePath, videoPath, isGradient, isDarkBannerText} = banner || {};
    const localizedStrings = getLocalizedStrings();
    const ContentComponent = type === TYPES.TEXT_TOP && isFullscreen ? ContentTextTop : ContentTextBottom;
    const isVideoAvailable = videoPath && (!imagePath || isFullscreen);
    const bannerRef = useRef(null);
    const videoPlayState = isPaused
        ? VIDEO_PLAY_STATES.PAUSED
        : isActiveSlide
          ? VIDEO_PLAY_STATES.PLAYING
          : VIDEO_PLAY_STATES.STOPPED;

    const handleVideoPlayStateChange = (playState) => {
        if (playState === VIDEO_PLAY_STATES.ENDED) {
            onEnded?.();
        }
    };

    const getGradient = () => {
        const showGradient = isFullscreen ? isFullscreenGradient : isGradient;

        if (!showGradient) {
            return null;
        }

        return isFullscreen && (isMobile || type === TYPES.TEXT_TOP) ? 'top' : 'bottom';
    };

    return (
        <Banner
            ref={bannerRef}
            className={cn(
                'h-full w-full bg-primary text-white',
                isFullscreen ? 'h-screen md:h-full' : 'h-[540px] overflow-hidden rounded-xl md:aspect-2/3 md:h-auto'
            )}
            onClick={onClick}
            isControlsVisible={false}
            loop={loop}
            videoRef={videoRef}
            isVideoAvailable={isVideoAvailable}
            autoplay={!isPaused}
            videoPath={videoPath}
            imagePath={imagePath}
            videoPlayState={videoPlayState}
            onVideoPlayStateChange={handleVideoPlayStateChange}
            onLoadedMetadata={onLoadedMetadata}
            onVideoError={onVideoError}
            isDarkBannerText={isDarkBannerText}
            onVideoCanPlay={onVideoCanPlay}
            onVideoWaiting={onVideoWaiting}
        >
            <ContentComponent
                isFullscreen={isFullscreen}
                gradient={getGradient()}
                subTitle={localizedStrings[subTitleKey]}
                title={localizedStrings[titleKey]}
                description={localizedStrings[descriptionKey]}
                firstButtonAction={firstButtonAction}
                firstButtonText={localizedStrings[firstButtonActionKey]}
                secondButtonAction={secondButtonAction}
                secondButtonText={localizedStrings[secondButtonActionKey]}
            />
        </Banner>
    );
};

const ContentTextBottom = ({isFullscreen, gradient, title, subTitle, description}) => {
    const isTextLarger = useIsTextLarger(isFullscreen);

    return (
        <HighlightsCardContentContainer isFullscreen={isFullscreen} gradient={gradient}>
            <h3
                className={twx('flex-1 text-14 leading-[10px]', {
                    'text-24 leading-[18px]': isTextLarger,
                })}
            >
                {subTitle}
            </h3>
            <h2
                className={twx('mb-0 text-24', {
                    'text-36': isTextLarger,
                    'line-clamp-2': !isFullscreen,
                })}
            >
                {title}
            </h2>
            <p
                className={cn('md:mt-2 md:leading-[20px]', {
                    'line-clamp-3': !isFullscreen,
                })}
            >
                {description}
            </p>
        </HighlightsCardContentContainer>
    );
};

const ContentTextTop = ({
    isFullscreen,
    gradient,
    title,
    subTitle,
    description,
    firstButtonAction,
    firstButtonText,
    secondButtonAction,
    secondButtonText,
}) => {
    const isTextLarger = useIsTextLarger(isFullscreen);

    return (
        <HighlightsCardContentContainer isFullscreen={isFullscreen} gradient={gradient}>
            <h3 className={twx('text-14 leading-[10px]', {'text-24 leading-[18px]': isTextLarger})}>{subTitle}</h3>
            <h2 className={twx('mb-0 mt-14 text-36', {'text-56 leading-[64px]': isTextLarger})}>{title}</h2>
            <p className='mt-4 flex-1 md:mt-2 md:leading-[20px]'>{description}</p>
            <div className='mt-4 flex w-full flex-col gap-4 md:mt-8'>
                <Buttons
                    firstButtonAction={firstButtonAction}
                    firstButtonText={firstButtonText}
                    secondButtonAction={secondButtonAction}
                    secondButtonText={secondButtonText}
                />
            </div>
        </HighlightsCardContentContainer>
    );
};

const Buttons = ({firstButtonAction, firstButtonText, secondButtonAction, secondButtonText}) => {
    return (
        <>
            {firstButtonText && firstButtonAction && (
                <Button primary dark text={firstButtonText} action={firstButtonAction} />
            )}
            {secondButtonText && secondButtonAction && (
                <Button secondary colored text={secondButtonText} action={secondButtonAction} />
            )}
        </>
    );
};

const Button = ({text, action, ...rest}) => {
    const redirectToExternalLink = useConfigExternalLink(action);
    const onClick = (e) => {
        e.stopPropagation();
        redirectToExternalLink();
    };

    return (
        <AppButton className='mt-0' {...rest} onClick={onClick}>
            {text}
        </AppButton>
    );
};

const useIsTextLarger = (isFullscreen) => {
    const isMobile = useIsMobileLayout();
    const isTextLarger = isMobile && isFullscreen;

    return isTextLarger;
};

export default HighlightItem;
