import React, {useRef} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import styles from '../../../../../HighlightsCarousel.module.scss';
import FullscreenHighlightsAnimatedContainer from '../FullscreenHighlightsAnimatedContainer';
import FullscreenHighlightsArrowButton from '../FullscreenHighlightsArrowButton';
import FullscreenHighlightsCarousel from '../FullscreenHighlightsCarousel/FullscreenHighlightsCarousel';
import useHighlightsInfiniteCarousels from './hooks/useHighlightsInfiniteCarousels';
import useHighlightsSwiper from './hooks/useHighlightsSwiper';

const FullscreenHighlightsCarouselList = ({carouselsFromConfig, isAutoPlayEnabled}) => {
    const CAROUSELS_TO_SHOW = 3;

    const sliderRef = useRef(null);
    const {
        setCarouselRef,
        carousels,
        carouselStates,
        handleCarouselLeave,
        handleCarouselEnter,
        handleCarouselEnded,
        handleSlideChange,
    } = useHighlightsInfiniteCarousels({sliderRef, carouselsFromConfig, visibleCount: CAROUSELS_TO_SHOW});
    const {options, currentSlide, handlePreviousClick, handleNextClick, handleOverlayClick} = useHighlightsSwiper({
        sliderRef,
        handleCarouselLeave,
        handleCarouselEnter,
    });

    return (
        <Swiper ref={sliderRef} className={styles.FullscreenCarousel} {...options}>
            {carousels.map(({highlights}, i) => (
                <SwiperSlide key={i}>
                    <FullscreenHighlightsAnimatedContainer
                        isBigger={i === currentSlide}
                        hasOverlay={i !== currentSlide}
                        onOverlayClick={() => handleOverlayClick(i)}
                    >
                        <FullscreenHighlightsCarousel
                            ref={(ref) => setCarouselRef(i, ref)}
                            highlights={highlights}
                            isAutoPlayEnabled={isAutoPlayEnabled}
                            isPlaying={carouselStates[i].isPlaying}
                            isEnded={carouselStates[i].isEnded}
                            currentSlide={carouselStates[i].currentSlide}
                            onSlideChange={(_, newSlide) => handleSlideChange(i, newSlide)}
                            onCarouselEnded={() => handleCarouselEnded(i)}
                        />
                    </FullscreenHighlightsAnimatedContainer>
                </SwiperSlide>
            ))}
            <FullscreenHighlightsArrowButton onClick={handleNextClick} />
            <FullscreenHighlightsArrowButton isFlipped onClick={handlePreviousClick} />
        </Swiper>
    );
};

export default FullscreenHighlightsCarouselList;
