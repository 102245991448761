import gsap from 'gsap';
import {useEffect, useRef} from 'react';

import {VIDEO_PLAY_STATES} from '@/consts/video/videoPlayStates';
import useDidUpdate from '@/hooks/useDidUpdate';

const useSlideButtonAnimation = ({progressLineRef, duration, playState, onEnded}) => {
    const {timelineRef, animationRef} = useGsapAnimation({progressLineRef, duration, playState, onEnded});

    useSyncPropsWithAnimation({timelineRef, animationRef, duration, playState});

    const getProgress = () => timelineRef.current.progress();

    const setProgress = (value) => {
        timelineRef.current.progress(value);
    };

    const reset = () => {
        timelineRef.current.progress(0);
    };

    return {getProgress, setProgress, reset};
};

const useGsapAnimation = ({progressLineRef, duration, playState, onEnded}) => {
    const DEFAULT_DURATION = 7;

    const timelineRef = useRef(null);
    const animationRef = useRef(null);

    const handleComplete = () => {
        timelineRef.current.pause();
        onEnded?.();
    };

    useEffect(() => {
        const isPaused = playState !== VIDEO_PLAY_STATES.PLAYING || !duration; // duration is 0 while video is loading
        const timeline = gsap.timeline({paused: isPaused});
        const animation = timeline.fromTo(
            progressLineRef.current,
            {
                width: 0,
            },
            {
                width: '100%',
                duration: duration || DEFAULT_DURATION,
                ease: 'linear',
                onComplete: handleComplete,
            }
        );

        timelineRef.current = timeline;
        animationRef.current = animation;

        return () => timelineRef.current.kill();
    }, []);

    return {timelineRef, animationRef};
};

const useSyncPropsWithAnimation = ({timelineRef, animationRef, duration, playState}) => {
    const syncPlayState = () => {
        if (playState === VIDEO_PLAY_STATES.PLAYING) {
            timelineRef.current.play();
        } else if (playState === VIDEO_PLAY_STATES.PAUSED) {
            timelineRef.current.pause();
        } else if (playState === VIDEO_PLAY_STATES.STOPPED) {
            const suppressEvents = true;

            timelineRef.current.pause().progress(0, suppressEvents);
        } else if (playState === VIDEO_PLAY_STATES.ENDED) {
            const suppressEvents = true;

            timelineRef.current.pause().progress(1, suppressEvents);
        }
    };

    useDidUpdate(() => {
        syncPlayState();
    }, [playState]);

    useDidUpdate(() => {
        if (duration) {
            animationRef.current.duration(duration);
            syncPlayState();
        }
    }, [duration]);
};

export default useSlideButtonAnimation;
