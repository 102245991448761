import React from 'react';
import {useSelector} from 'react-redux';

import WarningBanners from '@/views/WarningBanners/WarningBanners';

import {DamageIcon, TroubleshootDeviceRestrictedIcon} from '../../../components/Icons';
import {StickyHeader} from '../../../components/Layout';
import appConfig from '../../../config/appConfig';
import {AD_DAMAGE_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import routerService from '../../../services/route/routerService';
import {selectDeviceType} from '../../../state/selectors/device';
import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';
import AdvancedDiagnosticDamageCarousel from './AdvancedDiagnosticDamageCarousel';

const AdvancedDiagnosticDamage = () => {
    const deviceType = useSelector(selectDeviceType);
    const diagnosticData = appConfig.getAdConfig(deviceType, 'step1');
    const isRestrictedMarket = appConfig.getIsRestrictedMarket();
    const localizedStrings = getLocalizedStrings();
    const Icon = () =>
        isRestrictedMarket ? <TroubleshootDeviceRestrictedIcon className='h-16 w-16' /> : <DamageIcon />;

    useDocumentTitle(localizedStrings[AD_DAMAGE_HEADER_TITLE]);

    return (
        <div className='flex w-full flex-col'>
            <div className='w-full pb-4'>
                <StickyHeader onBack={routerService.goBack} title={localizedStrings[AD_DAMAGE_HEADER_TITLE]} />
                <AdvancedDiagnosticContainer>
                    <Icon />
                    <AdvancedDiagnosticTitle itemName='damagetitle' />
                    <AdvancedDiagnosticDescription itemName='damagedesc' />
                </AdvancedDiagnosticContainer>
                <AdvancedDiagnosticDamageCarousel carouselData={diagnosticData} deviceType={deviceType} />
            </div>
            <WarningBanners source={WarningBanners.SOURCES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticDamage;
