import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import CloseButton from '@/components/CloseButton/CloseButton';
import {TickCircle, WarningIcon} from '@/components/Icons';
import {StickyPageLayout} from '@/components/Layout';
import {PopupFullPage} from '@/components/Popup';
import {BROWSER_NOT_COMPATIBLE_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useExternalLink from '@/hooks/useExternalLink';
import useModalRoute from '@/hooks/useModalRoute';
import useScrollToPreviousPosition from '@/hooks/useScrollToPreviousPosition';
import amplitudeDeviceTracking from '@/services/amplitude/amplitudeDeviceTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import modalService from '@/services/modalService';
import navigatorService from '@/services/user/navigatorService';
import userBrowserService from '@/services/user/userBrowserService';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

const withBluefyLink = (link) => `https://bluefy.app/pwa?site=${link}`;

const BrowserNotCompatiblePopup = ({modalType, link, externalLink, ssoTemplate, ssoParams, linkProcessor}) => {
    const localizedStrings = getLocalizedStrings();
    const closeModal = () => modalService.hideModal(modalType);
    const {onClose} = useModalRoute({modalType, closeModal});

    // Use useExternalLink to get new link if it was refreshed after expiration
    const {link: linkFromState} = useExternalLink({
        externalLink,
        ssoTemplate,
        ssoParams,
        linkProcessor,
        disabled: true,
    });
    const linkToRedirect = ssoTemplate ? linkFromState || link : link;
    const finalLink = userBrowserService.isBluefyBrowser() ? linkToRedirect : withBluefyLink(linkToRedirect);

    const onClick = () => {
        amplitudeDeviceTracking.trackStartWithBluefyClick();
        navigatorService.copyToClipboard(finalLink);
    };

    useScrollToPreviousPosition();

    // shouldReturnFocusAfterClose is set to false because after closing of the popup it breaks Home page layout
    // if click was done on the NOT first carousel item
    return (
        <PopupFullPage shouldReturnFocusAfterClose={false} className='z-[102] flex flex-col bg-romance'>
            <StickyPageLayout
                className='items-center'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                    title: localizedStrings[BROWSER_NOT_COMPATIBLE_PAGE_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center pt-safe-offset-14'
            >
                <WarningIcon className='h-9 w-9' />
                <h1 className='mt-4 px-8 text-28'>
                    <AemEditableText pageName='modals' itemName='browserNotCompatibleTitle' />
                </h1>
                <div className='mt-8 bg-secondary px-8 py-6'>
                    <h2 className='text-24'>
                        <AemEditableText pageName='modals' itemName='browserNotCompatibleSubtitle' />
                    </h2>
                    <div className='mt-8 flex flex-col gap-6'>
                        {['browserNotCompatibleStep1', 'browserNotCompatibleStep2', 'browserNotCompatibleStep3'].map(
                            (itemName) => (
                                <AemEditableText
                                    key={itemName}
                                    pageName='modals'
                                    itemName={itemName}
                                    render={(text) => {
                                        return text ? <InstructionStep text={text} /> : null;
                                    }}
                                />
                            )
                        )}
                    </div>
                </div>
                <AppButton
                    className='mb-4 mt-25 max-w-[312px]'
                    primary
                    light
                    isExternalLink
                    to={finalLink}
                    onClick={onClick}
                    isTargetBlank
                >
                    <AemEditableText pageName='modals' itemName='browserNotCompatibleButton' />
                </AppButton>
                <WarningBanners source={WarningBanners.SOURCES.BROWSER_NOT_COMPATIBLE_POPUP} />
            </StickyPageLayout>
        </PopupFullPage>
    );
};

const InstructionStep = ({text}) => {
    return (
        <div className='flex items-center justify-start gap-2'>
            <TickCircle className='flex-shrink-0' />
            <span className='text-start'>{text}</span>
        </div>
    );
};

export default BrowserNotCompatiblePopup;
