import React from 'react';
import {useSelector} from 'react-redux';

import {StickyHeader} from '@/components/Layout';
import appConfig from '@/config/appConfig';
import {AD_CLEANING_CONTACTS_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {selectDeviceType} from '@/state/selectors/device';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';
import AdvancedDiagnosticCleaningContactsCarousel from './AdvancedDiagnosticCleaningContactsCarousel';

const AdvancedDiagnosticCleaningContacts = () => {
    const deviceType = useSelector(selectDeviceType);
    const diagnosticData = appConfig.getAdConfig(deviceType, 'cleaningGuide');
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[AD_CLEANING_CONTACTS_HEADER_TITLE]);

    return (
        <div className='flex w-full flex-col'>
            <div className='w-full pb-4 mt-safe-or-14'>
                <StickyHeader title={localizedStrings[AD_CLEANING_CONTACTS_HEADER_TITLE]} />
                <AdvancedDiagnosticTitle itemName='cleaningcontactstitle' />
                <AdvancedDiagnosticCleaningContactsCarousel carouselData={diagnosticData} deviceType={deviceType} />
            </div>
            <WarningBanners source={WarningBanners.SOURCES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticCleaningContacts;
