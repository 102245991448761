import {useImperativeHandle, useRef} from 'react';

import {VIDEO_PLAY_STATES} from '@/consts/video/videoPlayStates';

import {guideVideoUtils, handleGuideVideoUserControls} from '../utils/guideVideoUtils';
import useGuideVideoPlayStateSync from './useGuideVideoPlayStateSync';
import useGuideVideoStopTime from './useGuideVideoStopTime';

const useGuideVideo = ({ref, playState, videoStartTime, videoStopTime, onPlayStateChange, onLoadedMetadata}) => {
    const videoRef = useRef(null);

    const {handleLoadedMetadata: handleLoadedMetadataForVideoControls, ...videoControls} = guideVideoUtils({
        videoRef,
        playState,
        startTime: videoStartTime,
        stopTime: videoStopTime,
    });
    const {handleLoadedMetadata: handleLoadedMetadataForPlayStateSync} = useGuideVideoPlayStateSync({
        videoRef,
        playState,
        videoControls,
    });
    const {
        handlePlay: handlePlayForStopTime,
        handlePause: handlePauseForStopTime,
        handleTimeUpdate,
    } = useGuideVideoStopTime({
        videoRef,
        stopTime: videoStopTime,
        videoControls,
    });
    const {handleControlsClick} = handleGuideVideoUserControls({videoRef, videoControls});
    const {getVideoPlayState} = videoControls;

    useImperativeHandle(ref, () => ({element: videoRef.current, ...videoControls}));

    const handleLoadedMetadata = () => {
        handleLoadedMetadataForVideoControls();
        handleLoadedMetadataForPlayStateSync();
        onLoadedMetadata?.(videoRef.current);
    };

    const handlePlay = () => {
        handlePlayForStopTime();
        onPlayStateChange?.(VIDEO_PLAY_STATES.PLAYING);
    };

    const handlePause = () => {
        handlePauseForStopTime();

        const playState = getVideoPlayState();

        onPlayStateChange?.(playState);
    };

    return {
        videoRef,
        videoControls,
        handleLoadedMetadata,
        handlePlay,
        handleTimeUpdate,
        handlePause,
        handleControlsClick,
    };
};

export default useGuideVideo;
