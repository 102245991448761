import cn from 'classnames';
import React from 'react';

import useGreeting from '@/views/Greeting/useGreeting';

const GreetingAsSeparateSection = ({className}) => {
    const {title, description} = useGreeting();

    return (
        <article
            className={cn('bg-romance px-4 pb-4 pt-safe-offset-14 md:bg-transparent md:px-16 md:pt-24', className)}
        >
            <h1 className='mb-2 text-32'>{title}</h1>
            <p>{description}</p>
        </article>
    );
};

export default GreetingAsSeparateSection;
