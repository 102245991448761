import React from 'react';

import HighlightsCard from '../../HighlightsCard';
import HighlightsCarousel from '../../HighlightsCarousel';
import useDesktopHighlightsCarousels from '../hooks/useDesktopHighlightsCarousels';

const DesktopHighlightsCarouselList = ({carouselsFromConfig, isAutoPlayEnabled}) => {
    const {carousels, handleCarouselClick, handleSlideChange, handleCarouselEnded} =
        useDesktopHighlightsCarousels(carouselsFromConfig);

    return (
        <div className='flex gap-4 @[1912px]:gap-8'>
            {carouselsFromConfig.map(({highlights}, carouselIndex) => (
                <HighlightsCard key={carouselIndex} className='flex-1'>
                    <HighlightsCarousel
                        highlights={highlights}
                        autoplay={isAutoPlayEnabled}
                        initialSlide={carousels[carouselIndex].currentSlideIndex}
                        currentIndex={carousels[carouselIndex].currentSlideIndex}
                        isPlaying={carousels[carouselIndex].isPlaying}
                        onSlideClick={() => handleCarouselClick(carouselIndex)}
                        onSlideChange={(slideIndex) => handleSlideChange(carouselIndex, slideIndex)}
                        onCarouselEnded={handleCarouselEnded}
                        isInfinite={carousels.length === 1}
                    />
                </HighlightsCard>
            ))}
        </div>
    );
};

export default DesktopHighlightsCarouselList;
