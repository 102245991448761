import React, {forwardRef} from 'react';

import Video from '../Video';
import useGuideVideo from './hooks/useGuideVideo';

const GuideVideo = forwardRef(function GuideVideo(
    {
        autoplay = true,
        loop,
        content = {},
        src,
        thumbnailSrc,
        playState,
        onPlayStateChange,
        className,
        containerClassName,
        controlsClassName,
        controlAriaLabel,
        onError,
        children,
        onLoadedMetadata,
        isControlsVisible = true,
        onCanPlay,
        onWaiting,
    } = {},
    ref
) {
    const currentContent = content[0];
    const {videoStartTime = 0, videoStopTime, isControlsAvailable} = currentContent || {};
    const {videoRef, handleLoadedMetadata, handlePlay, handleTimeUpdate, handlePause, handleControlsClick} =
        useGuideVideo({ref, videoStartTime, videoStopTime, playState, onPlayStateChange, onLoadedMetadata});

    return (
        <Video
            ref={videoRef}
            autoplay={autoplay}
            loop={loop}
            className={className}
            containerClassName={containerClassName}
            controlsClassName={controlsClassName}
            controlAriaLabel={controlAriaLabel}
            isControlsVisible={isControlsVisible && isControlsAvailable}
            onLoadedMetadata={handleLoadedMetadata}
            muted
            onTimeUpdate={handleTimeUpdate}
            onControlsClickCustom={handleControlsClick}
            playsinline
            thumbnailSrc={thumbnailSrc}
            onError={onError}
            src={src}
            onPlay={handlePlay}
            onPause={handlePause}
            onCanPlay={onCanPlay}
            onWaiting={onWaiting}
        >
            {children}
        </Video>
    );
});

export default GuideVideo;
