import React from 'react';

import {PopupFullPage} from '@/components/Popup';

import FullscreenHighlightsCarouselList from './components/FullscreenHighlightsCarouselList/FullscreenHighlightsCarouselList';
import FullscreenHighlightsHeader from './components/FullscreenHighlightsHeader';

export const FullscreenDesktopHighlights = ({carouselsFromConfig, isAutoPlayEnabled, onClose}) => {
    return (
        <PopupFullPage
            className='z-10 bg-primary'
            isCloseVisible
            onClose={onClose}
            closeButtonClass='text-white top-7 right-13'
        >
            <div className='flex h-full flex-col pt-8'>
                <FullscreenHighlightsHeader />
                <CarouselListContainer>
                    <FullscreenHighlightsCarouselList
                        carouselsFromConfig={carouselsFromConfig}
                        isAutoPlayEnabled={isAutoPlayEnabled}
                    />
                </CarouselListContainer>
            </div>
        </PopupFullPage>
    );
};

const CarouselListContainer = ({children}) => {
    return <div className='mt-8 flex-1 py-8'>{children}</div>;
};

export default FullscreenDesktopHighlights;
