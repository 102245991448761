import appConfig from '../../config/appConfig';
import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';
import appInstanceService from '../app/appInstanceService';
import authDataService from '../auth/authDataService';
import iccService from '../icc/iccService';
import consoleLog from '../logger/consoleLogger';
import log from '../logger/log';
import {validateTokenMapping} from '../mapping/logMappings';
import server from '../server/server';
import cloudWatchProvider from './cloudWatchProvider';

let credentials = null;
let isAwsCredentialsRequested = false;
let currentAwsCredentialsRequest = null;
const FAILED_FETCH_TRIES_COUNT = 3;
let failedFetchCount = 0;

const isFetchAllowed = () => failedFetchCount < FAILED_FETCH_TRIES_COUNT;

const getCredentials = async (fetchNew) => {
    if (currentAwsCredentialsRequest) {
        await currentAwsCredentialsRequest;
    } else if (fetchNew || !credentials) {
        await fetchAwsCredentials();
    }

    return credentials;
};

const getRequestConfig = async () => {
    const authToken = await authDataService.getAuthToken({isAnonymousUserAllowed: true});
    const appInstanceId = appInstanceService.getAppInstanceId();

    const data = {
        app_id: appConfig.getIccApiHeader_a(),
        app_instance_id: appInstanceId,
        validate_market_iso: appConfig.getIccMarketName(),
        market_iso: appConfig.getIccMarketName(),
        market_iso_prefix: '',
    };
    const headers = {Token: authToken};

    return {data, headers};
};

const fetchAwsCredentials = async () => {
    const fetchIsNotAllowed = !isFetchAllowed();

    if (fetchIsNotAllowed) return;

    const apiUrl = appConfig.getAuthLogsUrl();

    if (!apiUrl) return;

    credentials = null;
    isAwsCredentialsRequested = true;
    const requestConfig = await getRequestConfig();

    try {
        currentAwsCredentialsRequest = server.post(apiUrl, requestConfig);
        const response = await currentAwsCredentialsRequest;

        currentAwsCredentialsRequest = null;
        isAwsCredentialsRequested = false;
        failedFetchCount = 0;

        log.debug(`fetchAwsCredentials: fetchAwsCredentials has successful response`);

        return onResponse(response);
    } catch (e) {
        if (e?.response?.status) {
            failedFetchCount = failedFetchCount + 1;
        }
        currentAwsCredentialsRequest = null;
        isAwsCredentialsRequested = false;
        consoleLog.info(`awsAuthenticationService: fetchAwsCredentials, error: ${e}`);

        return onError(e);
    }
};

const onResponse = (response) => {
    const mappedData = validateTokenMapping(response.data);

    updateCredentials(mappedData);

    return mappedData;
};

const onError = async (e) => {
    if (e?.response?.status === httpErrorsTypes.NOT_AUTHORIZED) {
        if (isAwsCredentialsRequested) return;

        isAwsCredentialsRequested = true;
        await iccService.refreshIccToken(true);

        return fetchAwsCredentials();
    }
    throw e;
};

const updateCredentials = (data) => {
    credentials = data;

    cloudWatchProvider.updateCloudWatchClient(credentials);
};

export default {
    getCredentials,
};
