import React, {forwardRef, useImperativeHandle} from 'react';

import HighlightItem from '../../../../../HighlightItem';
import HighlightsCarouselButton from '../../../../../HighlightsCarouselButton';
import FullscreenHighlightsSlider from '../FullscreenHighlightsSlider/FullscreenHighlightsSlider';
import FullscreenAnimatedSlideButton from './components/FullscreenAnimatedSlideButton/FullscreenAnimatedSlideButton';
import useHighlightsSlideButton from './hooks/useHighlightsSlideButton';
import useHighlightsSlideProgress from './hooks/useHighlightsSlideProgress';

const FullscreenHighlightsCarousel = forwardRef(function FullscreenHighlightsCarousel(
    {highlights, isAutoPlayEnabled, isPlaying, isEnded, currentSlide, onSlideChange, onCarouselEnded},
    ref
) {
    const {
        getButtonAnimationDuration,
        getButtonPlayState,
        handleLoadedMetadata,
        handleVideoWaiting,
        handleVideoCanPlay,
        handleVideoError,
    } = useHighlightsSlideButton({
        highlights,
        isAutoPlayEnabled,
        isCarouselPlaying: isPlaying,
        isCarouselEnded: isEnded,
        currentSlide,
    });
    const {setButtonRef, setVideoRef, getSlideProgress, setSlideProgress, resetSlideProgress} =
        useHighlightsSlideProgress(currentSlide);

    useImperativeHandle(ref, () => ({
        getSlideProgress,
        setSlideProgress,
        resetSlideProgress,
    }));

    const handleSlideButtonClick = (i) => {
        onSlideChange?.(currentSlide, i);
    };

    const handleSlideEnded = (i) => {
        const isLastSlide = i === highlights.length - 1;

        if (isLastSlide) {
            onCarouselEnded?.();
        } else {
            const nextSlide = i + 1;

            onSlideChange?.(i, nextSlide);
        }
    };

    return (
        <FullscreenHighlightsSlider currentSlide={currentSlide}>
            <FullscreenHighlightsSlider.Buttons asChild>
                <HighlightsCarouselButton.Container>
                    {highlights.map((_, i) => (
                        <FullscreenAnimatedSlideButton
                            key={i}
                            ref={(ref) => setButtonRef(i, ref)}
                            duration={getButtonAnimationDuration(i)}
                            playState={getButtonPlayState(i)}
                            onClick={() => handleSlideButtonClick(i)}
                            onEnded={() => handleSlideEnded(i)}
                        />
                    ))}
                </HighlightsCarouselButton.Container>
            </FullscreenHighlightsSlider.Buttons>

            <FullscreenHighlightsSlider.Slides>
                {highlights.map((highlight, i) => (
                    <FullscreenHighlightsSlider.Slide key={i} index={i}>
                        <HighlightItem
                            videoRef={(ref) => setVideoRef(i, ref)}
                            loop={!isAutoPlayEnabled}
                            isFullscreen
                            isActiveSlide={i === currentSlide}
                            isPaused={!isPlaying}
                            onLoadedMetadata={(metadata) => handleLoadedMetadata(metadata, i)}
                            onVideoCanPlay={() => handleVideoCanPlay(i)}
                            onVideoWaiting={() => handleVideoWaiting(i)}
                            onVideoError={() => handleVideoError(i)}
                            {...highlight}
                        />
                    </FullscreenHighlightsSlider.Slide>
                ))}
            </FullscreenHighlightsSlider.Slides>
        </FullscreenHighlightsSlider>
    );
});

export default FullscreenHighlightsCarousel;
