import appConfig from '@/config/appConfig';
import arrayUtils from '@/utils/arrayUtils';

export const filterSectionsById = ({list, id}) => {
    return list?.filter((x) => x?.pageSection?.id === id) || [];
};

export const getPageSectionById = (id) => {
    const dynamicPageSections = arrayUtils.filterEmptyObjects(appConfig.getDynamicPages()?.map((x) => x.pageSections));
    const pageSections = [].concat(...dynamicPageSections);

    return pageSections.find((x) => x.id === id) || {};
};
