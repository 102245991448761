import cn from 'classnames';
import React, {useEffect} from 'react';

import {HOME_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import HomePageTopNavigation from '@/containers/HomePageTopNavigation/HomePageTopNavigation';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import useIsTopBannerEnabled from '@/hooks/useIsTopBannerEnabled';
import useIsWelcomeMode from '@/hooks/useIsWelcomeMode';
import amplitudeHomePageTracking from '@/services/amplitude/amplitudeHomePageTracking';
import analyticsService from '@/services/analyticsService';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '@/services/homePageNavigationService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {twx} from '@/utils/tailwindUtils';
import DynamicPage from '@/views/DynamicPage/DynamicPage';
import HomePageLayout from '@/views/Home/HomePageLayout';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import useShowEnableNotificationModal from './components/useShowEnableNotificationModal';

const HomePage = () => {
    const {pageAnimationClassName, topNavigationAnimationClassName} = useWelcomeAnimation();
    const localizedStrings = getLocalizedStrings();
    const isTopBannerEnabled = useIsTopBannerEnabled();

    useDocumentTitle(localizedStrings[HOME_PAGE_HEADER_TITLE]);
    useShowEnableNotificationModal();

    useEffect(() => {
        amplitudeHomePageTracking.trackHomePageScreen();
        analyticsService.pushTestEvent();
    }, []);

    return (
        <HomePageLayout className={cn('bg-secondary', pageAnimationClassName)}>
            <HomePageTopNavigation
                title={localizedStrings[HOME_PAGE_HEADER_TITLE]}
                source={HOME_PAGE_NAVIGATION_SOURCE_TYPES.HOME}
                className={topNavigationAnimationClassName}
                childRenderer={(isBgVisible) => (
                    <div
                        className={cn(
                            'transition-colors duration-300 ease-in-out',
                            isBgVisible ? 'text-primary' : isTopBannerEnabled ? 'text-romance' : 'text-transparent'
                        )}
                        id='greetingPortal'
                    />
                )}
            />
            <DynamicPage />
            <WarningBanners source={WarningBanners.SOURCES.HOME} className='md:p-12 md:py-4' />
        </HomePageLayout>
    );
};

export default HomePage;

const useWelcomeAnimation = () => {
    const {isWelcomeMode, isAnimationStarted, isAnimationFinished} = useIsWelcomeMode();

    return {
        pageAnimationClassName: {'animate-fade-in': isWelcomeMode && !isAnimationFinished},
        topNavigationAnimationClassName: twx({
            'opacity-0 transition-opacity duration-500 md:z-[111]': isWelcomeMode && !isAnimationFinished,
            'opacity-100': isAnimationStarted,
        }),
    };
};
