import React, {useEffect} from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import {MaxWidthContainer} from '@/components/Layout';
import SupportAndStoreTemplate from '@/components/SupportAndStoreTemplate/SupportAndStoreTemplate';
import * as advanceDiagnosticIconTypes from '@/consts/advanceDiagnostic/advanceDiagnosticIconTypes';
import amplitudeADTracking from '@/services/amplitude/amplitudeADTracking';
import appRouterService from '@/services/route/appRouterService';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticIcon from '../components/AdvancedDiagnosticIcon';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';

const AdvancedDiagnosticFinishScreen = () => {
    useEffect(() => {
        amplitudeADTracking.trackADInitialQuestionnaireScreen();
    }, []);

    return (
        <div className='flex w-full flex-col'>
            <AdvancedDiagnosticContainer isDeviceHero>
                <AdvancedDiagnosticIcon type={advanceDiagnosticIconTypes.SUCCESS} />
                <MaxWidthContainer className='mx-8 md:mx-0'>
                    <AdvancedDiagnosticTitle itemName='finishtitle' />
                    <AdvancedDiagnosticDescription itemName='finishdesc' />
                    <AppButton
                        primary
                        light
                        onClick={appRouterService.forwardToDevicesPage}
                        className='sm:min-w-[312px]'
                    >
                        <AemComponent component={EditableText} pageName='ad' itemName='finishbutton' />
                    </AppButton>
                </MaxWidthContainer>

                <SupportAndStoreTemplate className='mt-[40px] md:flex-col lg:flex-row' />
            </AdvancedDiagnosticContainer>{' '}
            <WarningBanners source={WarningBanners.SOURCES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticFinishScreen;
