import {VIDEO_PLAY_STATES} from '@/consts/video/videoPlayStates';

const guideVideoUtils = ({videoRef, playState, startTime, stopTime}) => {
    const video = videoRef.current;

    const getVideoCurrentTime = () => video?.currentTime;

    const getVideoEndTime = () => stopTime || video?.duration || 0;

    const getVideoPlayState = () => {
        if (!video) {
            return playState;
        }

        const isPaused = video.paused;
        const isPlaying = !isPaused;

        if (isPlaying) {
            return VIDEO_PLAY_STATES.PLAYING;
        }

        const isAtStart = video.currentTime === 0;

        if (isPaused && isAtStart) {
            return VIDEO_PLAY_STATES.STOPPED;
        }

        const isAtEnd = video.currentTime >= getVideoEndTime(video);

        if (isPaused && isAtEnd) {
            return VIDEO_PLAY_STATES.ENDED;
        }

        if (isPaused) {
            return VIDEO_PLAY_STATES.PAUSED;
        }

        return playState;
    };

    const resetVideoToStart = () => {
        if (video) {
            video.currentTime = startTime;
        }
    };

    const resetVideoToEnd = () => {
        if (video) {
            const endTime = getVideoEndTime(videoRef);

            video.currentTime = endTime;
        }
    };

    const playVideo = () => {
        if (video) {
            const playState = getVideoPlayState();

            if (playState !== VIDEO_PLAY_STATES.PLAYING) {
                if (playState === VIDEO_PLAY_STATES.ENDED) {
                    resetVideoToStart();
                }

                video.play().catch(() => {}); // catch is necessary
            }
        }
    };

    const pauseVideo = () => {
        if (video) {
            const playState = getVideoPlayState();

            if (playState === VIDEO_PLAY_STATES.PLAYING) {
                video.pause();
            }
        }
    };

    const stopVideo = () => {
        if (video) {
            const playState = getVideoPlayState();

            if (playState !== VIDEO_PLAY_STATES.STOPPED) {
                pauseVideo();
                resetVideoToStart();
            }
        }
    };

    const endVideo = () => {
        if (video) {
            const playState = getVideoPlayState();

            if (playState !== VIDEO_PLAY_STATES.ENDED) {
                pauseVideo();
                resetVideoToEnd();
            }
        }
    };

    const seekVideo = (time) => {
        if (video) {
            video.currentTime = time;
        }
    };

    const restartVideo = () => {
        if (video) {
            resetVideoToStart();
            playVideo();
        }
    };

    const handleLoadedMetadata = () => resetVideoToStart();

    return {
        getVideoCurrentTime,
        getVideoEndTime,
        getVideoPlayState,
        resetVideoToStart,
        resetVideoToEnd,
        playVideo,
        pauseVideo,
        stopVideo,
        endVideo,
        seekVideo,
        restartVideo,
        handleLoadedMetadata,
    };
};

const handleGuideVideoUserControls = ({videoRef, videoControls}) => {
    const {getVideoPlayState, playVideo, pauseVideo} = videoControls;

    const handleControlsClick = () => {
        const video = videoRef.current;

        if (video) {
            const playState = getVideoPlayState();

            if (playState === VIDEO_PLAY_STATES.PLAYING) {
                pauseVideo();
            } else {
                playVideo();
            }
        }
    };

    return {handleControlsClick};
};

export {guideVideoUtils, handleGuideVideoUserControls};
