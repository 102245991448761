import {VIDEO_PLAY_STATES} from '@/consts/video/videoPlayStates';
import useDidUpdate from '@/hooks/useDidUpdate';

const useGuideVideoPlayStateSync = ({videoRef, playState, videoControls}) => {
    const {playVideo, pauseVideo, stopVideo, endVideo} = videoControls;

    const canSeekVideo = () => videoRef.current?.readyState >= HTMLMediaElement.HAVE_METADATA;

    const syncPlayState = () => {
        if (!canSeekVideo()) return;

        switch (playState) {
            case VIDEO_PLAY_STATES.PLAYING:
                playVideo();
                break;
            case VIDEO_PLAY_STATES.PAUSED:
                pauseVideo();
                break;
            case VIDEO_PLAY_STATES.STOPPED:
                stopVideo();
                break;
            case VIDEO_PLAY_STATES.ENDED:
                endVideo();
                break;
            default:
                break;
        }
    };

    useDidUpdate(syncPlayState, [playState]);

    const handleLoadedMetadata = () => syncPlayState();

    return {handleLoadedMetadata};
};

export default useGuideVideoPlayStateSync;
