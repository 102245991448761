import {useState} from 'react';

const useHighlightsSwiper = ({sliderRef, handleCarouselLeave, handleCarouselEnter}) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const options = {
        slidesPerView: 3,
        spaceBetween: 0,
        speed: 400,
        centeredSlides: true,
        loop: true,
        allowTouchMove: false,
        onRealIndexChange: ({realIndex}) => setCurrentSlide(realIndex),
        onSlideChangeTransitionStart: ({previousRealIndex}) => handleCarouselLeave(previousRealIndex),
        onSlideChangeTransitionEnd: ({realIndex}) => handleCarouselEnter(realIndex),
    };

    const handlePreviousClick = () => {
        sliderRef.current?.swiper.slidePrev();
    };

    const handleNextClick = () => {
        sliderRef.current?.swiper.slideNext();
    };

    const handleOverlayClick = (i) => {
        sliderRef.current?.swiper.slideToLoop(i);
    };

    return {options, currentSlide, handlePreviousClick, handleNextClick, handleOverlayClick};
};

export default useHighlightsSwiper;
