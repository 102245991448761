import {VIDEO_PLAY_STATES} from '@/consts/video/videoPlayStates';

import useHighlightsCarouselIsVideoBuffering from '../../../../../../../hooks/useHighlightsCarouselIsVideoBuffering';
import useHighlightsCarouselSlidesAnimationDuration from '../../../../../../../hooks/useHighlightsCarouselSlidesAnimationDuration';

const useHighlightsSlideButton = ({
    highlights,
    isAutoPlayEnabled,
    isCarouselPlaying,
    isCarouselEnded,
    currentSlide,
}) => {
    const {
        getAnimationDuration: getButtonAnimationDuration,
        handleLoadedMetadata,
        handleVideoError,
    } = useHighlightsCarouselSlidesAnimationDuration({
        highlights,
        autoplay: isAutoPlayEnabled,
        currentIndex: currentSlide,
    });
    const {getIsVideoBuffering, handleVideoWaiting, handleVideoCanPlay} =
        useHighlightsCarouselIsVideoBuffering(highlights);

    const getButtonPlayState = (i) => {
        if (isCarouselEnded) {
            return VIDEO_PLAY_STATES.ENDED;
        }

        if (isCarouselPlaying) {
            const isButtonRelatedToCurrentSlide = i === currentSlide;
            const isVideoBuffering = getIsVideoBuffering(i);

            if (isButtonRelatedToCurrentSlide) {
                return isVideoBuffering ? VIDEO_PLAY_STATES.PAUSED : VIDEO_PLAY_STATES.PLAYING;
            }

            const isButtonRelatedToPastSlide = i < currentSlide;

            if (isButtonRelatedToPastSlide) {
                return VIDEO_PLAY_STATES.ENDED;
            }

            const isButtonRelatedToFutureSlide = i > currentSlide;

            if (isButtonRelatedToFutureSlide) {
                return VIDEO_PLAY_STATES.STOPPED;
            }
        } else {
            return VIDEO_PLAY_STATES.PAUSED;
        }
    };

    return {
        getButtonAnimationDuration,
        getButtonPlayState,
        handleLoadedMetadata,
        handleVideoWaiting,
        handleVideoCanPlay,
        handleVideoError,
    };
};

export default useHighlightsSlideButton;
