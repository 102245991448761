import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {ArrowSmallIcon} from '@/components/Icons';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import {useHomePageHighlightsSectionId} from '@/containers/HomePage/components/HomePageHighlights/contexts/HomePageHighlightsSectionIdContext';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import usePageSectionTitle from '@/hooks/usePageSectionTitle';
import {getLocalizedStrings} from '@/services/localization/localizationService';

const HighlightsHeader = ({onViewAllClick}) => {
    const id = useHomePageHighlightsSectionId();
    const isMobile = useIsMobileLayout();
    const localizedStrings = getLocalizedStrings();

    const titleText = usePageSectionTitle(id);
    const buttonText = localizedStrings[localizationKeys.HIGHLIGHTS_VIEW_ALL_BUTTON];

    if (!titleText && !buttonText) return null;

    return (
        <header className='flex items-center justify-between gap-2'>
            <h1 className='m-0 overflow-hidden text-ellipsis whitespace-nowrap text-24 tracking-04 md:text-32 md:tracking-normal'>
                {titleText}
            </h1>
            {!isMobile && buttonText && (
                <ButtonRaw className='flex shrink-0 gap-2 tracking-05 text-scarpa-flow' onClick={onViewAllClick}>
                    <span className='flex-1 pb-0.5'>{buttonText}</span>
                    <ArrowSmallIcon />
                </ButtonRaw>
            )}
        </header>
    );
};

export default HighlightsHeader;
