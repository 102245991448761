import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import AppButton from '@/components/Button/AppButton/AppButton';
import {TncDataCollectionIcon} from '@/components/Icons';
import {StickyPageLayout} from '@/components/Layout';
import {DATA_COLLECTION_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import {WEB_PREFERENCE_CENTER} from '@/consts/tnc/tncTypes';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import useTermsOfUsePopup from '@/hooks/useTermsOfUsePopup';
import amplitudeProfileTracking from '@/services/amplitude/amplitudeProfileTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import tncService from '@/services/tncService';
import {makeSelectAcceptedTncByType, makeSelectTncByType} from '@/state/selectors/consumer';
import {hideLoader, showLoader} from '@/state/slices/loadersSlice';
import {dispatch} from '@/state/store';
import TermsOfUseDescription from '@/views/Tnc/TermsOfUseDescription/TermsOfUseDescription';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import TncPageContainer from '../TncPageContainer';

const DataCollectionPage = () => {
    const isMobileLayout = useIsMobileLayout();
    const dataCollectionTnc = useSelector(makeSelectTncByType(WEB_PREFERENCE_CENTER));
    const acceptedTermsOfUse = useSelector(makeSelectAcceptedTncByType(WEB_PREFERENCE_CENTER));

    const {openPopup} = useTermsOfUsePopup();
    const isUpdatedTnc = !!acceptedTermsOfUse;
    const {description, legals, updateDescription} = dataCollectionTnc || {};
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[DATA_COLLECTION_HEADER_TITLE]);

    useEffect(() => {
        amplitudeProfileTracking.trackUpdateYourDataScreen();
    }, []);

    useEffect(() => {
        if (!isUpdatedTnc) {
            appRouterService.forwardToTncDetailsPage(WEB_PREFERENCE_CENTER);
            return;
        }

        if (dataCollectionTnc && legals) return;

        appRouterService.forwardToHomePageIfNeed();
    }, [dataCollectionTnc, legals]);

    const setTnc = async (isAccepted) => {
        dispatch(showLoader());

        await tncService.setDataCollectionTnc(legals, isAccepted);

        dispatch(hideLoader());
    };

    const onAcceptButtonClick = () => setTnc(true);
    const onDeclineButtonClick = () => setTnc(false);

    const descriptionText = isUpdatedTnc ? updateDescription : description;

    return (
        <TncPageContainer>
            <StickyPageLayout
                className='items-center'
                desktopClassName='w-[506px] justify-center py-5'
                desktopContentClassName='flex flex-col items-center'
                desktopFooterClassName='mt-6 md:mt-8'
                headerOptions={{
                    title: localizedStrings[DATA_COLLECTION_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center py-4 pt-safe-offset-14'
                footer={
                    <>
                        <div className='flex flex-col gap-4'>
                            <AppButton className='mt-0' primary light onClick={onAcceptButtonClick}>
                                <AemEditableText pageName='tnc' itemName='dataCollectionAgree' />
                            </AppButton>
                            <AppButton className='mt-0' secondary light onClick={onDeclineButtonClick}>
                                <AemEditableText pageName='tnc' itemName='dataCollectionDecline' />
                            </AppButton>
                            <AppButton
                                className='mx-auto mt-0'
                                tertiary
                                onClick={() => appRouterService.forwardToTncDetailsPage(WEB_PREFERENCE_CENTER)}
                            >
                                <AemEditableText pageName='tnc' itemName='dataCollectionPreferenCecenter' />
                            </AppButton>
                        </div>
                        {!isMobileLayout && <WarningBanners source={WarningBanners.SOURCES.TNC} />}
                    </>
                }
            >
                <div className='mb-4 flex flex-1 flex-col items-center px-4 md:mb-0'>
                    <TncDataCollectionIcon />
                    <h1 className='mt-9 text-28 md:mb-0 md:mt-4'>
                        {!isUpdatedTnc && <AemEditableText pageName='tnc' itemName='dataCollectionTitle' />}
                        {isUpdatedTnc && <AemEditableText pageName='tnc' itemName='dataCollectionTitleUpdate' />}
                    </h1>
                    <TermsOfUseDescription
                        className='mt-4'
                        innerHtml={descriptionText}
                        tncType={WEB_PREFERENCE_CENTER}
                        onTncLinkClick={openPopup}
                    />
                </div>
                {isMobileLayout && <WarningBanners source={WarningBanners.SOURCES.TNC} />}
            </StickyPageLayout>
        </TncPageContainer>
    );
};

export default DataCollectionPage;
