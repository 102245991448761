import React from 'react';

import WarningBanners from '@/views/WarningBanners/WarningBanners';

import TipsAndTutorials from '../../views/TipsAndTutorials/TipsAndTutorials';

const TipsAndTutorialsPage = () => (
    <div className='flex w-full flex-col'>
        <TipsAndTutorials />
        <WarningBanners source={WarningBanners.SOURCES.TIPS_AND_TUTORIALS} />
    </div>
);

export default TipsAndTutorialsPage;
