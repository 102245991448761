import cn from 'classnames';
import React from 'react';
import {createPortal} from 'react-dom';

import Banner from '@/components/Banner/Banner';
import {AppButton} from '@/components/Button';
import ROUTE_PATHS from '@/consts/route/routePaths';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import useLocationPathname from '@/hooks/useLocationPathname';
import useRefetchExternalConsumerProfile from '@/hooks/useRefetchExternalConsumerProfile';
import amplitudeHomePageTracking from '@/services/amplitude/amplitudeHomePageTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import helpers from '@/utils/helpers';
import {twx} from '@/utils/tailwindUtils';

import HomePageGreeting from '../Greeting/HomePageGreeting';
import HomePageLoyaltyButtonsBar from './components/HomePageLoyaltyButtonsBar';
import WelcomeContent from './components/WelcomeContent';
import HomePageLoyaltyBanner from './HomePageLoyaltyBanner';
import useTopBanner from './hooks/useTopBanner';
import useWelcomeTopBannerAnimation from './useWelcomeTopBannerAnimation';

const HomePageTopBanner = ({id}) => {
    const {
        bannerGreetingRef,
        welcomeGreetingRef,
        isWelcomeModeInProgress,
        isAnimationStarted,
        startAnimation,
        translation,
        bannerAnimationClassName,
        greetingAnimationClassName,
        greetingAnimationStyle,
        bannerContentAnimationClassName,
        loyaltyButtonsBarAnimationClassName,
    } = useWelcomeTopBannerAnimation();
    const {pathname} = useLocationPathname();
    const {isLoyaltyBanner, topBanner, loyaltyButtons} = useTopBanner({id});

    const {
        titleKey,
        descriptionKey,
        buttonActionKey,
        imagePath,
        action,
        isGradient,
        isDarkBannerText,
        amplitudeTrackingValue,
    } = topBanner || {};

    const redirectToExternalLink = useConfigExternalLink(action);
    const isShowButton = buttonActionKey && redirectToExternalLink;

    const localizedStrings = getLocalizedStrings();
    const isLoyaltyButtonsBarEnabled = isLoyaltyBanner && loyaltyButtons?.length;
    const isHomePage = pathname === ROUTE_PATHS.HOME_PAGE;

    useRefetchExternalConsumerProfile();

    if (!topBanner) return null;

    const greetingPortal = isHomePage && document.getElementById('greetingPortal');
    const onButtonClick = () => {
        amplitudeHomePageTracking.trackHomePageTopBannerButtonClick(amplitudeTrackingValue);
        helpers.runFunction(redirectToExternalLink);
    };

    return (
        <div
            className={cn(
                'bg-romance md:mb-6 md:bg-transparent',
                isLoyaltyButtonsBarEnabled ? 'h-[574px] md:h-[482px]' : 'h-[518px] md:h-[434px]'
            )}
        >
            <Banner
                className={twx('h-[518px] w-full font-bold text-romance md:h-[434px]', bannerAnimationClassName)}
                imgClassName='object-top animate-fade-in'
                imagePath={imagePath}
                isGradient={isGradient}
                isDarkBannerText={isDarkBannerText}
                gradient='linear-gradient(180deg, rgba(52, 48, 61, 0.00) 0.01%, rgba(48, 44, 56, 0.17) 8.34%, rgba(33, 31, 39, 0.78) 59.37%, #1C1A21 99.99%)'
            >
                <HomePageGreeting
                    className={twx(
                        'absolute w-full px-4 pr-22 text-start pt-safe-or-4',
                        {'lg:hidden': isHomePage},
                        {'md:pl-16 md:pr-32': !isHomePage},
                        {[greetingAnimationClassName]: isHomePage}
                    )}
                    style={greetingAnimationStyle}
                    ref={bannerGreetingRef}
                />
                {greetingPortal &&
                    createPortal(
                        <HomePageGreeting
                            className={twx('hidden font-bold tracking-04 lg:block', {
                                [greetingAnimationClassName]: isHomePage,
                            })}
                            style={greetingAnimationStyle}
                            ref={bannerGreetingRef}
                        />,
                        greetingPortal
                    )}
                <div
                    className={twx(
                        'absolute bottom-8 w-full px-4 text-start md:px-16',
                        {'bottom-[64px] md:bottom-20': isLoyaltyButtonsBarEnabled},
                        bannerContentAnimationClassName
                    )}
                >
                    {isLoyaltyBanner && <HomePageLoyaltyBanner onClick={onButtonClick} loyaltyTopBanner={topBanner} />}
                    {!isLoyaltyBanner && (
                        <>
                            <h1 className='text-32 md:mb-2'>{localizedStrings[titleKey]}</h1>
                            <p className='font-normal'>{localizedStrings[descriptionKey]}</p>
                        </>
                    )}
                    {isShowButton && (
                        <AppButton primary dark className='mt-8 font-normal md:mt-6' onClick={onButtonClick}>
                            {localizedStrings[buttonActionKey]}
                        </AppButton>
                    )}
                </div>
                {isLoyaltyButtonsBarEnabled && (
                    <HomePageLoyaltyButtonsBar
                        buttons={loyaltyButtons}
                        className={twx(
                            'absolute bottom-[-56px] left-4 right-4 md:-bottom-12 md:left-16 md:right-16',
                            loyaltyButtonsBarAnimationClassName
                        )}
                    />
                )}
                {isWelcomeModeInProgress && (
                    <WelcomeContent
                        isAnimationStarted={isAnimationStarted}
                        onClick={startAnimation}
                        greetingRef={welcomeGreetingRef}
                        greetingTranslation={translation}
                        isDarkBannerText={isDarkBannerText}
                    />
                )}
            </Banner>
        </div>
    );
};

export default HomePageTopBanner;
