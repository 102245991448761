import React, {useEffect} from 'react';

import CloseButton from '@/components/CloseButton/CloseButton';
import {StickyPageLayout} from '@/components/Layout';
import {PopupFullPage} from '@/components/Popup';
import {LEVIA_POPUP_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import amplitudeInboxTracking from '@/services/amplitude/amplitudeInboxTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import modalService from '@/services/modalService';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import LeviaLearnMoreArticle from './LeviaLearnMoreArticle/LeviaLearnMoreArticle';
import LeviaLearnMoreButtons from './LeviaLearnMoreArticle/LeviaLearnMoreButtons';

const LeviaLearnMorePopup = ({modalType, source}) => {
    const onClose = () => modalService.hideModal(modalType);
    const localizedStrings = getLocalizedStrings();

    useEffect(() => {
        amplitudeInboxTracking.trackActionScreen(source);
    }, []);

    return (
        <PopupFullPage className='z-20 flex flex-col bg-romance'>
            <StickyPageLayout
                className='items-center'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                    title: localizedStrings[LEVIA_POPUP_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center'
                footer={<LeviaLearnMoreButtons onCancel={onClose} />}
            >
                <LeviaLearnMoreArticle />
                <WarningBanners source={WarningBanners.SOURCES.LEVIA_POPUP} />
            </StickyPageLayout>
        </PopupFullPage>
    );
};

export default LeviaLearnMorePopup;
