import cn from 'classnames';
import React from 'react';

import HomePageNavigationBar from './HomePageNavigationBar/HomePageNavigationBar';

const HomePageLayout = ({children, className, activePath}) => {
    return (
        <div className={cn('ica-page relative w-full text-start lg:border-l-[length:var(--sidebar-width)]', className)}>
            {children}
            <HomePageNavigationBar activePath={activePath} />
        </div>
    );
};

export default HomePageLayout;
