import {createSelector} from 'reselect';

import appConfig from '../../config/appConfig';
import {DEVICE_TYPES} from '../../consts/device/deviceTypes';
import * as iccConsumerAggregatedAttributeTypes from '../../consts/icc/iccConsumerAggregatedAttributeTypes';
import {ICC_PRODUCT_STATUS_TYPES} from '../../consts/icc/iccProductStatusTypes';
import * as tncTypes from '../../consts/tnc/tncTypes';
import {TERMS_OF_USE} from '../../consts/tnc/tncTypes';
import devicePlatformService from '../../services/device/devicePlatformService';
import deviceTypesService from '../../services/device/deviceTypesService';
import marketConfigService from '../../services/marketConfigService';
import tncService from '../../services/tncService';

const selectConsumer = (state) => state.consumer;

const selectConsumerProductsFetchDate = createSelector(selectConsumer, (state) => state.productsFetchDate);
const selectIsConsumerProductsFetchFailed = createSelector(selectConsumer, (state) => state.productsFailed);
const selectConsumerProducts = createSelector(selectConsumer, (state) => state.products);

const selectConsumerData = createSelector(selectConsumer, (state) => state.data);
const selectConsumerFetchDate = createSelector(selectConsumer, (state) => state.consumerFetchDate);

const selectConsumerId = createSelector(selectConsumerData, (data) => data?.consumerId);

const selectConsumerLanguageCode = createSelector(selectConsumerData, (data) => data?.defaultLocaleCode);

const selectConsumerProductsInfo = createSelector(selectConsumer, (state) => {
    return state.productsInfo;
});

const selectConsumerProductsMergedInfo = createSelector(
    selectConsumerProducts,
    selectConsumerProductsInfo,
    (products, productsInfo) => {
        if (!products) return;
        if (products?.length === 0) return [];
        if (!productsInfo) return;
        if (productsInfo.length === 0) return [];

        const registeredProducts = products.filter(
            (product) => !product.isHolder && product.status === ICC_PRODUCT_STATUS_TYPES.REGISTERED
        );
        const holders = products.filter((product) => product.isHolder);

        const consumerProductMergedInfo = registeredProducts.map((product) => {
            const {codentify, deviceSerialNumber, parentCodentifyID, parentProductID, defaultHolderType} = product;
            const productInfo = productsInfo.find(
                (productInfo) => productInfo.deviceSerialNumber === deviceSerialNumber
            );
            const {codentifyID, colorHex, mediaId, brandFamily, defaultProductName, type} = productInfo || {};

            if (!codentify && !codentifyID) return null;

            const productType = type ? type : devicePlatformService.getDeviceTypeBySerialNumber(deviceSerialNumber);
            const defaultProductHolderType = productInfo?.defaultHolderType
                ? productInfo?.defaultHolderType
                : defaultHolderType;

            let mergedHolderInfo;

            if (parentCodentifyID || parentProductID) {
                const holder = holders.find(
                    (h) =>
                        (parentCodentifyID && h.parentCodentifyID === parentCodentifyID) ||
                        (parentProductID && h.parentProductID === parentProductID)
                );

                if (holder) {
                    const holderInfo = productsInfo.find(
                        (productInfo) => productInfo.deviceSerialNumber === holder.deviceSerialNumber
                    );
                    const {colorHex, mediaId, brandFamily, defaultProductName, type} = holderInfo || {};

                    const holderType = type
                        ? type
                        : devicePlatformService.getDeviceTypeBySerialNumber(holder.deviceSerialNumber);

                    mergedHolderInfo = {
                        ...holder,
                        colorHex,
                        mediaId,
                        brandFamily,
                        defaultProductName: defaultProductName
                            ? defaultProductName
                            : deviceTypesService.getProductNameByType(holderType),
                        type: holderType,
                    };
                }
            }

            return {
                ...product,
                colorHex,
                mediaId,
                brandFamily,
                defaultProductName: defaultProductName
                    ? defaultProductName
                    : deviceTypesService.getProductNameByType(productType),
                defaultHolderType: defaultProductHolderType,
                isDeviceSupported: marketConfigService.isDeviceSupported(productType),
                type: productType,
                holder: mergedHolderInfo,
            };
        });

        return consumerProductMergedInfo.filter((product) => product && product.type !== DEVICE_TYPES.DEV_TYPE_UNKNOWN);
    }
);

const selectIsConsumerProductsFetched = createSelector(
    selectIsConsumerProductsFetchFailed,
    selectConsumerProductsMergedInfo,
    (isFailed, products) => {
        //failed request considered as fetched
        return isFailed || products !== undefined;
    }
);

const selectIsAnyConsumerProducts = createSelector(selectConsumerProductsMergedInfo, (products) => {
    return products?.length > 0;
});

const selectIsConsumerDataFetched = createSelector(selectConsumerId, (consumerId) => !!consumerId);

const selectConsumerName = createSelector(selectConsumerData, (data) => {
    const {firstName, lastName} = data || {};
    const fullName = [firstName, lastName].filter((str) => str?.length).join(' ');

    return fullName;
});

const selectConsumerFirstName = createSelector(selectConsumerData, (data) => {
    const {firstName} = data || {};

    return firstName;
});

const findConsumerAggregatedAttribute = (type) =>
    createSelector(selectConsumerData, (data) => {
        const {consumerAggregatedAttributes} = data || {};

        if (!consumerAggregatedAttributes) return null;

        const value = consumerAggregatedAttributes.find(
            (x) => x.attributeCode?.toLowerCase() === type.toLowerCase()
        )?.value;

        if (typeof value === 'string') {
            return value;
        }

        return null;
    });

const selectIsLoyaltyMember = createSelector(
    findConsumerAggregatedAttribute(iccConsumerAggregatedAttributeTypes.IS_LOYALTY_MEMBER),
    (value) => {
        return value?.toLowerCase() === 'true';
    }
);

const selectLoyaltyLevel = createSelector(
    findConsumerAggregatedAttribute(iccConsumerAggregatedAttributeTypes.LOYALTY_LEVEL),
    (value) => {
        const loyaltyLevels = appConfig.getLoyaltyLevels();

        if (!loyaltyLevels?.length || !value) return;

        const level = loyaltyLevels.find((x) => x.loyaltyKey?.toLowerCase() === value.toLowerCase());

        if (!level) return;

        const nextLevel = loyaltyLevels.find((x) => x.order === level.order + 1);

        return {...level, nextLevel};
    }
);

const selectLoyaltyPoints = createSelector(
    findConsumerAggregatedAttribute(iccConsumerAggregatedAttributeTypes.LOYALTY_POINTS),
    (value) => {
        return parseInt(value) || 0;
    }
);

/* TNC */
const selectConsumerTnc = createSelector(selectConsumer, (state) => state.tnc);

const selectConsumerAcceptedTnc = createSelector(selectConsumer, (state) => state.acceptedTnc);

const makeSelectTncByType = (type) =>
    createSelector(selectConsumerTnc, (tnc) => (tnc ? tnc.find((item) => item.screenId === type) : {}));

const makeSelectAcceptedTncByType = (type) =>
    createSelector(selectConsumerAcceptedTnc, (acceptedTnc) =>
        acceptedTnc ? acceptedTnc.find((item) => item.screenId === type) : {}
    );

const selectTncLegals = createSelector(selectConsumerTnc, selectConsumerAcceptedTnc, (tnc, acceptedTnc) => {
    const legals = filterLegals(tnc);
    const acceptedLegals = filterLegals(acceptedTnc);

    return [...legals, ...acceptedLegals];
});

const selectTermsOfUseLegalCode = createSelector(
    makeSelectTncByType(TERMS_OF_USE),
    makeSelectAcceptedTncByType(TERMS_OF_USE),
    (termsOfUse, acceptedTermsOfUse) => {
        const legals = acceptedTermsOfUse?.legals.length ? acceptedTermsOfUse.legals : termsOfUse?.legals || [];
        const sortedLegals = [...legals].sort((a, b) => a.position - b.position);

        return sortedLegals[0]?.code;
    }
);

const makeSelectTncLegalDataByCode = (code) =>
    createSelector(selectTncLegals, (legals) => {
        const legalData = tncService.getTncLegalByTermsCode(legals, code);

        return legalData;
    });

const makeSelectTncLegalsByType = (type) =>
    createSelector(makeSelectTncByType(type), (tnc) => {
        return tnc?.legals || [];
    });

const filterLegals = (tnc) => {
    return tnc?.length
        ? tnc
              .map(({legals}) => {
                  return legals ? [...legals] : [];
              })
              .reduce((prev, next) => [...prev, ...next])
        : [];
};

const selectConsumerProductsToRegUnreg = createSelector(selectConsumer, (state) => state.productsToRegUnreg);

const makeSelectConsumerProductToRegUnreg = (codentify) =>
    createSelector(selectConsumerProductsToRegUnreg, (products) => products?.find((x) => x.codentify === codentify));

const makeSelectUrlByType = (tncType) =>
    createSelector(selectConsumerTnc, (acceptedTnc) => {
        if (!acceptedTnc?.length) return;

        const legals = acceptedTnc.find((item) => item.screenId === tncType)?.legals;

        if (!legals?.length) return;

        const legalsSorted = [...legals].sort((a, b) => a.position < b.position);

        return legalsSorted[0]?.webLink;
    });

const selectAttributionNoticeUrl = makeSelectUrlByType(tncTypes.TERMS_OF_USE);

const selectPrivacyNoticeUrl = makeSelectUrlByType(tncTypes.PRIVACY_NOTICE);
/* TNC */

const makeSelectExternalLink = (key, locale) =>
    createSelector(selectConsumer, (data) => {
        const {externalLinks = []} = data || {};

        return (
            externalLinks.find(
                (x) => x.key?.toLowerCase() === key?.toLowerCase() && x.locale?.toLowerCase() === locale?.toLowerCase()
            ) || {}
        );
    });

export {
    makeSelectAcceptedTncByType,
    makeSelectConsumerProductToRegUnreg,
    makeSelectExternalLink,
    makeSelectTncByType,
    makeSelectTncLegalDataByCode,
    makeSelectTncLegalsByType,
    selectAttributionNoticeUrl,
    selectConsumerAcceptedTnc,
    selectConsumerData,
    selectConsumerFetchDate,
    selectConsumerFirstName,
    selectConsumerId,
    selectConsumerLanguageCode,
    selectConsumerName,
    selectConsumerProducts,
    selectConsumerProductsFetchDate,
    selectConsumerProductsInfo,
    selectConsumerProductsMergedInfo,
    selectConsumerProductsToRegUnreg,
    selectConsumerTnc,
    selectIsAnyConsumerProducts,
    selectIsConsumerDataFetched,
    selectIsConsumerProductsFetched,
    selectIsLoyaltyMember,
    selectLoyaltyLevel,
    selectLoyaltyPoints,
    selectPrivacyNoticeUrl,
    selectTermsOfUseLegalCode,
    selectTncLegals,
};
