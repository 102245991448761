import React from 'react';

import * as localizationKeys from '@/consts/localization/localizationKeys';
import {getLocalizedStrings} from '@/services/localization/localizationService';

const FullscreenHighlightsHeader = () => {
    const localizedStrings = getLocalizedStrings();
    const text = localizedStrings[localizationKeys.FULLSCREEN_HIGHLIGHTS_PAGE_HEADER_TITLE];

    return (
        <h1 className='m-0 line-clamp-1 h-10 text-ellipsis pl-16 pr-28 text-left text-28 leading-14 text-romance'>
            {text}
        </h1>
    );
};

export default FullscreenHighlightsHeader;
