import cn from 'classnames';
import React, {forwardRef, useEffect, useRef, useState} from 'react';

import server from '../../services/server/server';
import helpers from '../../utils/helpers';
import Button from '../Button/Button';
import Image from '../Image/Image';
import LoaderCentered from '../Loader/AppLoader/LoaderCentered';
import styles from './Video.module.scss';

const Video = (props, ref) => {
    const {
        autoplay,
        className,
        containerClassName,
        controlsClassName,
        controlAriaLabel,
        isControlsVisible = true,
        loop,
        muted,
        onCanPlay,
        onControlsClickCustom,
        onError,
        onTimeUpdate,
        onLoad,
        onLoadedMetadata,
        playsinline,
        thumbnailSrc,
        src,
        onPlay: onPlayFromProps,
        onPause: onPauseFromProps,
        onEnded,
        onWaiting,
        children,
    } = props;

    const videoRef = useRef(ref);
    const [isLoaded, setIsLoaded] = useState(false);

    const [isVideoCanStart, setIsVideoCanStart] = useState(false);
    const [isAnimationFinished, setIsAnimationFinished] = useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const onCanPlayFunc = () => {
        helpers.runFunction(onCanPlay);
        setIsVideoCanStart(true);
    };

    const onLoadFunc = () => {
        helpers.runFunction(onLoad);
    };

    const onErrorFunc = () => {
        helpers.runFunction(onError);
    };

    const onTransitionEnd = () => {
        setIsAnimationFinished(true);
    };

    const thumbnailClassName = cn(styles.Thumbnail, {[styles.Hidden]: isVideoCanStart}, className);
    const controlButtonWrapperClassName = cn(styles.ControlButtonWrapper, controlsClassName, {
        [styles.Visible]: isLoaded,
    });
    const controlButtonClassName = cn(styles.ControlButton, {
        [styles.Paused]: !isVideoPlaying,
    });

    useEffect(() => {
        if (isAnimationFinished) {
            if (autoplay) {
                videoRef.current.play?.();
            }

            onCanPlayFunc();
        }
    }, [isAnimationFinished]);

    useEffect(() => {
        server
            .get(src)
            .then(onLoadFunc)
            .catch(onErrorFunc)
            .finally(() => {
                setIsLoaded(true);
            });
    }, []);

    const onControlButtonClick = () => {
        const video = videoRef.current;

        if (onControlsClickCustom) {
            helpers.runFunction(onControlsClickCustom);
        } else {
            video.paused ? video.play() : video.pause();
        }
    };

    const onPlay = () => {
        setIsVideoPlaying(true);
        onPlayFromProps?.();
    };
    const onPause = () => {
        setIsVideoPlaying(false);
        onPauseFromProps?.();
    };

    return (
        <>
            <div className={cn(styles.VideoContainer, containerClassName)} aria-hidden='true'>
                {isLoaded ? (
                    <>
                        {children}
                        <video
                            autoPlay={autoplay}
                            className={className}
                            loop={loop}
                            onEnded={onEnded}
                            muted={muted}
                            onCanPlay={onCanPlayFunc}
                            onTimeUpdate={onTimeUpdate}
                            onPause={onPause}
                            onPlay={onPlay}
                            playsInline={playsinline}
                            onLoadedMetadata={onLoadedMetadata}
                            ref={ref ? ref : videoRef}
                            src={src}
                            onWaiting={onWaiting}
                        />
                        {isControlsVisible && (
                            <Button
                                className={controlButtonWrapperClassName}
                                onClick={onControlButtonClick}
                                tabIndex='-1'
                                ariaLabel={controlAriaLabel}
                            >
                                <div className={controlButtonClassName} />
                            </Button>
                        )}
                    </>
                ) : (
                    <LoaderCentered className={styles.Loader} />
                )}

                <div className={cn(styles.VideoContainer, containerClassName)} aria-hidden='true'>
                    <Image onTransitionEnd={onTransitionEnd} className={thumbnailClassName} src={thumbnailSrc} />
                </div>
            </div>
        </>
    );
};

export default forwardRef(Video);
