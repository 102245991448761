import cn from 'classnames';
import React, {useEffect} from 'react';

import {SHOP_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import amplitudeShopPageTracking from '@/services/amplitude/amplitudeShopPageTracking';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '@/services/homePageNavigationService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import DynamicPage from '@/views/DynamicPage/DynamicPage';
import HomePageLayout from '@/views/Home/HomePageLayout';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import HomePageTopNavigation from '../HomePageTopNavigation/HomePageTopNavigation';
import styles from './ShopPage.module.scss';

const ShopPage = () => {
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[SHOP_PAGE_HEADER_TITLE]);

    useEffect(() => {
        amplitudeShopPageTracking.trackShopPageScreen();
    }, []);

    return (
        <HomePageLayout className={cn('bg-secondary', styles.ShopPage)}>
            <HomePageTopNavigation
                title={localizedStrings[SHOP_PAGE_HEADER_TITLE]}
                source={HOME_PAGE_NAVIGATION_SOURCE_TYPES.SHOP}
            />
            <DynamicPage />
            <WarningBanners source={WarningBanners.SOURCES.SHOP} />
        </HomePageLayout>
    );
};

export default ShopPage;
