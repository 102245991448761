import React, {useState} from 'react';

import Banner from '@/components/Banner/Banner';
import {AppButton} from '@/components/Button';
import CloseButton from '@/components/CloseButton/CloseButton';
import {StickyHeader} from '@/components/Layout';
import PopupResponsive from '@/components/Popup/PopupResponsive';
import PromotedBadge from '@/components/PromotedBadge/PromotedBadge';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import helpers from '@/utils/helpers';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import AutoDeliveryBenefits from './AutoDeliveryBenefits';
import HowItWorksSection from './HowItWorksSection';

const AutoDeliveryDetailsStatusNoneTemplate = ({
    banner,
    headerKey,
    benefitsList,
    howItWorksList,
    amplitudeEvent,
    benefitsItemName,
    howItWorksItemName,
    onClose,
}) => {
    const isMobile = useIsMobileLayout();
    const [popupRef, setPopupRef] = useState();

    const {imagePath, videoPath, isGradient, titleKey, subTitleKey, descriptionKey, buttonActionKey, action} =
        banner || {};
    const localizedStrings = getLocalizedStrings();
    const redirectToExternalLink = useConfigExternalLink(action);
    const isShowButton = buttonActionKey && localizedStrings[buttonActionKey] && redirectToExternalLink;

    useDocumentTitle(localizedStrings[headerKey]);

    const onButtonClick = () => {
        helpers.runFunction(amplitudeEvent);
        redirectToExternalLink();
    };

    return (
        <PopupResponsive
            contentRef={setPopupRef}
            className='overflow-auto text-start md:ml-[calc(var(--sidebar-width)/2)] md:w-[calc(100%-var(--sidebar-width)*1.5)] md:max-w-[1016px] md:rounded-xl md:p-0'
            closeButtonClass='md:text-romance'
            onClose={onClose}
        >
            <StickyHeader
                scrollElement={popupRef}
                title={localizedStrings[headerKey]}
                endIcon={<CloseButton onClick={onClose} />}
            />
            <Banner
                className='h-[422px] bg-tertiary md:h-[308px]'
                imagePath={imagePath}
                videoPath={videoPath}
                isGradient={isGradient}
                gradient='linear-gradient(180deg, rgba(52, 48, 61, 0) 39.99%, #1C1A21 100%)'
            >
                <div className='absolute bottom-8 w-full px-4 text-start text-white md:px-12'>
                    <div className='mb-2 flex md:mb-4'>
                        <PromotedBadge
                            className='max-w-full whitespace-nowrap [&>*]:overflow-hidden [&>*]:text-ellipsis'
                            badgeText={localizedStrings[subTitleKey]}
                        />
                    </div>
                    <h1 className='mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-24 leading-[40px] md:mb-4 md:text-28'>
                        {localizedStrings[titleKey]}
                    </h1>
                    <p
                        className='line-clamp-3 whitespace-pre-line text-14 md:max-w-96 md:tracking-04'
                        dangerouslySetInnerHTML={{__html: localizedStrings[descriptionKey]}}
                    />
                    {isShowButton && (
                        <AppButton
                            primary
                            dark
                            className='mt-4 whitespace-nowrap [&>*]:overflow-hidden [&>*]:text-ellipsis'
                            onClick={onButtonClick}
                        >
                            {localizedStrings[buttonActionKey]}
                        </AppButton>
                    )}
                </div>
            </Banner>
            <div className='md:grid md:grid-cols-2 md:gap-8 md:p-8'>
                <AutoDeliveryBenefits
                    benefitsList={benefitsList}
                    itemName={benefitsItemName}
                    className='hidden px-4 py-8 has-[h2,li_*]:block md:m-0 md:p-0'
                />
                <HowItWorksSection
                    howItWorksList={howItWorksList}
                    itemName={howItWorksItemName}
                    className='hidden border-t border-mischka first:border-0 has-[h2,li_*]:block md:m-0 md:border-0 md:p-0'
                />
            </div>
            {isMobile && <WarningBanners source={WarningBanners.SOURCES.AUTO_DELIVERY} />}
        </PopupResponsive>
    );
};

export default AutoDeliveryDetailsStatusNoneTemplate;
