import React from 'react';
import {useSelector} from 'react-redux';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {RegularCleaningIcon} from '@/components/Icons';
import {StickyHeader} from '@/components/Layout';
import appConfig from '@/config/appConfig';
import {AD_REMOVE_STICK_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import amplitudeADTracking from '@/services/amplitude/amplitudeADTracking';
import deviceTypesService from '@/services/device/deviceTypesService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import routerService from '@/services/route/routerService';
import {selectDeviceType} from '@/state/selectors/device';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import AdvancedDiagnosticCarousel from '../../AdvancedDiagnosticCarousel/AdvancedDiagnosticCarousel';
import AdvancedDiagnosticContainer from '../../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticRemoveStickSlide from '../AdvancedDiagnosticRemoveStickSlide';

const AdvancedDiagnosticRemoveStick = () => {
    const deviceType = useSelector(selectDeviceType);
    const isDelta = deviceTypesService.isDelta(deviceType);
    const diagnosticData = appConfig.getAdConfig(deviceType, 'step4');
    const titleItemName = isDelta ? 'removestickdeltatitle' : 'removesticktitle';
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[AD_REMOVE_STICK_HEADER_TITLE]);

    return (
        <div className='w-full'>
            <StickyHeader onBack={routerService.goBack} title={localizedStrings[AD_REMOVE_STICK_HEADER_TITLE]} />
            <AdvancedDiagnosticContainer>
                <RegularCleaningIcon />
                <h4 className='mb-6 mt-4'>
                    <AemComponent component={EditableText} pageName='ad' itemName={titleItemName} />
                </h4>
            </AdvancedDiagnosticContainer>
            <div className='mt-8'>
                <AdvancedDiagnosticCarousel
                    carouselData={diagnosticData}
                    dotsClassName='h-0' //to center arrows vertically
                    onAfterChange={amplitudeADTracking.trackADRemoveStickInstructionScroll}
                >
                    {diagnosticData.map((data, i) => {
                        const {title, description} = data;
                        const isLastSlide = diagnosticData.length - 1 === i;

                        return (
                            <AdvancedDiagnosticRemoveStickSlide
                                key={i}
                                title={title}
                                description={description}
                                isLastSlide={isLastSlide}
                            />
                        );
                    })}
                </AdvancedDiagnosticCarousel>
            </div>
            <WarningBanners source={WarningBanners.SOURCES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticRemoveStick;
