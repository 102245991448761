import {useRef} from 'react';

import {restartCssAnimation} from '@/utils/domUtils';

const useHighlightsCarouselSlideRestart = ({highlights, animationClass}) => {
    const defaultVideoRefs = Array(highlights.length).fill([]); // array of arrays
    // slider creates additional copies of slides to support infinite scrolling, and we should store
    // references to all the videos inside those slides, so this is array of slides with array of refs
    const videoRefs = useRef(defaultVideoRefs);
    const animatedButtonRefs = useRef([]);

    // one index might have several refs (different copies of same slide)
    const setVideoRef = (i, ref) => {
        let refList = videoRefs.current[i];

        if (ref) {
            refList.push(ref);
        } else {
            refList = [];
        }

        videoRefs.current[i] = refList;
    };

    const setAnimatedButtonRef = (i, ref) => {
        animatedButtonRefs.current[i] = ref;
    };

    const restartSlide = (slideIndex) => {
        const button = animatedButtonRefs.current[slideIndex];

        if (button) {
            restartCssAnimation({element: button, animationClass});
        }

        const refList = videoRefs.current[slideIndex];

        for (const ref of refList) {
            ref?.restartVideo();
        }
    };

    return {setVideoRef, setAnimatedButtonRef, restartSlide};
};

export default useHighlightsCarouselSlideRestart;
