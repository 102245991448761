import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {getPageSectionById} from '@/services/pageSectionService';

const usePageSectionTitle = (id) => {
    const localizedStrings = getLocalizedStrings();
    const {titleKey, desktopTitleKey} = getPageSectionById(id);
    const isMobile = useIsMobileLayout();

    return localizedStrings[isMobile ? titleKey : desktopTitleKey];
};

export default usePageSectionTitle;
