import React, {useEffect, useState} from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import {MaxWidthContainer} from '@/components/Layout';
import GuideVideo from '@/components/Video/GuideVideo/GuideVideo';
import appConfig from '@/config/appConfig';
import * as advanceDiagnosticScreenTypes from '@/consts/advanceDiagnostic/advanceDiagnosticScreenTypes';
import {VIDEO_PLAY_STATES} from '@/consts/video/videoPlayStates';
import SignedImage from '@/containers/System/SignedImage';
import amplitudeADTracking from '@/services/amplitude/amplitudeADTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import rtlService from '@/services/rtlService';

import AdvancedDiagnosticCarousel from '../AdvancedDiagnosticCarousel/AdvancedDiagnosticCarousel';
import NotNowButtonAD from '../components/NotNowButtonAD';
import useRedirectToPhase2 from '../hooks/useRedirectToPhase2';
import ADVANCED_DIAGNOSTIC_CLEANING_CONTACTS_DATA from './advancedDiagnosticCleaningContactsData';

const AdvancedDiagnosticCleaningContactsCarousel = ({carouselData, deviceType}) => {
    const defaultImage = ADVANCED_DIAGNOSTIC_CLEANING_CONTACTS_DATA[deviceType]?.defaultImage;
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const isLastSlide = currentSlideIndex === carouselData.length - 1;

    const onSlide = (index) => setCurrentSlideIndex(parseInt(index));
    const isRestrictedMarket = appConfig.getIsRestrictedMarket();

    useEffect(() => {
        if (!isLastSlide) return;

        amplitudeADTracking.trackADInitialQuestionnaireScreen();
    }, [isLastSlide]);

    return (
        <div className='mt-8'>
            <AdvancedDiagnosticCarousel carouselData={carouselData} onSlide={onSlide}>
                {carouselData.map((slideData, index) => (
                    <SlideComponent
                        key={index}
                        slideData={slideData}
                        index={index}
                        currentSlideIndex={currentSlideIndex}
                        defaultImage={isRestrictedMarket ? undefined : defaultImage}
                    />
                ))}
            </AdvancedDiagnosticCarousel>
            <ButtonsSection isLastSlide={isLastSlide} />
        </div>
    );
};

const SlideComponent = ({slideData, index, currentSlideIndex, defaultImage}) => {
    const localizedStrings = getLocalizedStrings();
    const {title, description, video, thumbnail, image, videoStartTime, videoStopTime} = slideData;
    const [isVideoVisible, setIsVideoVisible] = useState(!!video);
    const imagePath = appConfig.getCombinedPathAssetPath(image);
    const {isVideoPlay} = getVideoControls(index, currentSlideIndex);
    const playState = isVideoPlay ? VIDEO_PLAY_STATES.PLAYING : VIDEO_PLAY_STATES.STOPPED;

    return (
        <div
            key={index}
            dir={rtlService.getDir()}
            className='flex h-full flex-col rounded bg-secondary text-primary md:w-[436px] lg:w-[597px]'
        >
            <div className={'w-full overflow-hidden bg-romance'}>
                {isVideoVisible ? (
                    <GuideVideo
                        src={video}
                        thumbnailSrc={thumbnail}
                        playState={playState}
                        content={[{videoStartTime, videoStopTime}]}
                        onError={() => setIsVideoVisible(false)}
                        containerClassName='h-[176px] md:h-[244px] lg:h-[335px]'
                        className='absolute left-1/2 top-1/2 z-10 w-full -translate-x-1/2 -translate-y-1/2'
                        controlsClassName='m-0 right-4 left-[unset] z-20 rtl:left-4 rtl:right-[unset]'
                    />
                ) : (
                    <SignedImage
                        imgClassName='w-full h-[176px] md:h-[244px] lg:h-[335px] object-contain bg-center'
                        defaultImage={defaultImage}
                        mediaData={imagePath}
                        isThumbnailAvailable
                        signIfNull
                    />
                )}
            </div>
            <MaxWidthContainer className='m-auto mt-4 max-w-[328px] justify-center px-6 py-7'>
                <h1 className='mb-3 text-20'>{localizedStrings[title]}</h1>
                <p>
                    <span
                        className='whitespace-pre-line'
                        dangerouslySetInnerHTML={{
                            __html: localizedStrings[description],
                        }}
                    />
                </p>
            </MaxWidthContainer>
        </div>
    );
};

const ButtonsSection = ({isLastSlide}) => {
    const isOkGotIt = isLastSlide;

    const okGotItClick = () => {
        appRouterService.forwardToDevicesPage();
    };
    const redirectToPhase2 = useRedirectToPhase2();
    const onTryAgainClick = () => redirectToPhase2();

    if (isOkGotIt) {
        return (
            <div className='mt-6'>
                <AppButton primary light onClick={okGotItClick} className='mx-auto mb-4 mt-0 w-full max-w-[312px]'>
                    <AemComponent component={EditableText} pageName='ad' itemName='cleaningcontactsgotitbutton' />
                </AppButton>
            </div>
        );
    }

    return (
        <div className='mt-6 px-8'>
            {isLastSlide && (
                <AppButton primary light onClick={onTryAgainClick} className='mx-auto mb-4 mt-0'>
                    <AemComponent component={EditableText} pageName='ad' itemName='cleaningcontactstryagainbutton' />
                </AppButton>
            )}
            <NotNowButtonAD
                secondary
                isHolder
                customClassName='mx-auto mt-0'
                screenName={advanceDiagnosticScreenTypes.CLEANING_CONTACTS_SCREEN}
            >
                <AemComponent component={EditableText} pageName='ad' itemName='cleaningcontactsnotnowbutton' />
            </NotNowButtonAD>
        </div>
    );
};

const getVideoControls = (index, currentSlideIndex) => {
    const isActiveSlide = index === currentSlideIndex;

    return {
        isVideoPlay: isActiveSlide,
    };
};

export default AdvancedDiagnosticCleaningContactsCarousel;
