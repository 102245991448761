import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import ROUTE_PATHS from '@/consts/route/routePaths';
import appRouterService from '@/services/route/appRouterService';
import {selectAggregatedSettings} from '@/state/selectors/consumerAggregatedSettings';

const useTncPageRedirection = () => {
    const aggregatedSettings = useSelector(selectAggregatedSettings);
    const isTncRelatedPage = useIsTncRelatedPage();

    useEffect(() => {
        if (isTncRelatedPage) return;
        if (!aggregatedSettings) return;

        const {allMandatoryTermsApproved, allNonMandatoryTermsReviewed} = aggregatedSettings;
        const isTncAccepted = allMandatoryTermsApproved && allNonMandatoryTermsReviewed;

        if (isTncAccepted) return;

        appRouterService.forwardToTncPage();
    }, [aggregatedSettings, isTncRelatedPage]);
};

export default useTncPageRedirection;

const useIsTncRelatedPage = () => {
    const {pathname} = useLocation();

    return pathname === ROUTE_PATHS.TNC || pathname === ROUTE_PATHS.TNC_DETAILS;
};
