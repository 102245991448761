import {useRef} from 'react';

import arrayUtils from '@/utils/arrayUtils';

const useHighlightsDuplicateGroups = ({carousels, initialCount, visibleCount}) => {
    const carouselRefs = useRef([]);

    const setCarouselRef = (i, ref) => {
        carouselRefs.current[i] = ref;
    };

    const getGroupSize = () => Math.max(initialCount, 2);
    const getGroupIndex = (i) => i % getGroupSize();
    const getIsInGroup = (i, group) => i % getGroupSize() === group;
    const getNextInGroup = (i) => (i + getGroupSize()) % carousels.length;

    const syncWithGroup = (states, i) => {
        const duplicate = getNextInGroup(i);

        if (i === duplicate) {
            return states;
        }

        const currentState = states[i];
        const duplicateState = {...currentState};
        const newStates = arrayUtils.updateArrayImmutably(states, [duplicate, duplicateState]);

        return newStates;
    };

    const fixGroupProgressDesync = (i, slide) => {
        const duplicate = getNextInGroup(i);

        if (i === duplicate) return;

        const currentCarousel = carouselRefs.current[i];
        const duplicateCarousel = carouselRefs.current[duplicate];

        const progress = currentCarousel.getSlideProgress(slide);

        duplicateCarousel.setSlideProgress(slide, progress);
    };

    const resetGroupSlideProgress = (i, slide) => {
        const duplicate = getNextInGroup(i);
        const currentCarousel = carouselRefs.current[i];
        const duplicateCarousel = carouselRefs.current[duplicate];

        currentCarousel.resetSlideProgress(slide);
        duplicateCarousel.resetSlideProgress(slide);
    };

    return {
        setCarouselRef,
        getGroupIndex,
        getIsInGroup,
        syncWithGroup,
        fixGroupProgressDesync,
        resetGroupSlideProgress,
    };
};

export default useHighlightsDuplicateGroups;
