import {useState} from 'react';

const useDesktopHighlightsCarousels = (carouselsFromConfig) => {
    const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
    const initialCarousels = carouselsFromConfig.map(({highlights}, i) => ({
        isPlaying: i === currentCarouselIndex,
        currentSlideIndex: 0,
        slideCount: highlights.length,
        ended: false,
    }));
    const [carousels, setCarousels] = useState(initialCarousels);
    const {playCarousel, endCarousel, changeCarouselSlide} = getCarouselControls({currentCarouselIndex});

    const handleCarouselClick = (carouselIndex) => {
        if (carouselIndex !== currentCarouselIndex) {
            const newCarousels = playCarousel({
                carousels,
                newCarouselIndex: carouselIndex,
            });

            setCarousels(newCarousels);
            setCurrentCarouselIndex(carouselIndex);
        }
    };

    const handleSlideChange = (carouselIndex, slideIndex) => {
        let newCarousels = changeCarouselSlide({
            carousels,
            carouselIndex,
            newSlideIndex: slideIndex,
        });

        if (carouselIndex !== currentCarouselIndex) {
            newCarousels = playCarousel({
                carousels: newCarousels,
                newCarouselIndex: carouselIndex,
            });
        }

        setCarousels(newCarousels);
        setCurrentCarouselIndex(carouselIndex);
    };

    const handleCarouselEnded = () => {
        let newCarousels = carousels;
        const hasOneCard = carouselsFromConfig.length === 1;

        if (hasOneCard) {
            newCarousels = changeCarouselSlide({
                carousels: newCarousels,
                carouselIndex: 0,
                newSlideIndex: 0,
            });
            setCarousels(newCarousels);
        } else {
            const oldCarouselIndex = currentCarouselIndex;
            const newCarouselIndex = (oldCarouselIndex + 1) % carouselsFromConfig.length;

            newCarousels = endCarousel({
                carousels: newCarousels,
                carouselIndex: oldCarouselIndex,
            });
            newCarousels = playCarousel({carousels: newCarousels, newCarouselIndex});

            setCarousels(newCarousels);
            setCurrentCarouselIndex(newCarouselIndex);
        }
    };

    return {
        carouselsFromConfig,
        carousels,
        handleCarouselClick,
        handleSlideChange,
        handleCarouselEnded,
    };
};

const getCarouselControls = ({currentCarouselIndex}) => {
    const playCarousel = ({carousels, newCarouselIndex}) => {
        const oldCarouselIndex = currentCarouselIndex;

        if (newCarouselIndex === oldCarouselIndex) {
            return carousels;
        }

        const oldCarousel = carousels[oldCarouselIndex];
        const newCarousel = carousels[newCarouselIndex];
        const oldCarouselPaused = {
            ...oldCarousel,
            isPlaying: false,
        };
        const newCarouselPlaying = {
            ...newCarousel,
            isPlaying: true,
        };

        let newCarousels = Object.assign(
            [],
            carousels,
            {[oldCarouselIndex]: oldCarouselPaused},
            {[newCarouselIndex]: newCarouselPlaying}
        );

        if (newCarousel.ended) {
            newCarousels = changeCarouselSlide({
                carousels: newCarousels,
                carouselIndex: newCarouselIndex,
                newSlideIndex: 0,
            });
        }

        return newCarousels;
    };

    const endCarousel = ({carousels, carouselIndex}) => {
        const carousel = carousels[carouselIndex];
        const carouselEnded = {
            ...carousel,
            ended: true,
        };

        const newCarousels = Object.assign([], carousels, {[carouselIndex]: carouselEnded});

        return newCarousels;
    };

    const changeCarouselSlide = ({carousels, carouselIndex, newSlideIndex}) => {
        const carousel = carousels[carouselIndex];

        if (newSlideIndex === carousel.currentSlideIndex && !carousel.ended) {
            return carousels;
        }

        const newCarousel = {
            ...carousel,
            currentSlideIndex: newSlideIndex,
            ended: false,
        };
        const newCarousels = Object.assign([], carousels, {[carouselIndex]: newCarousel});

        return newCarousels;
    };

    return {playCarousel, endCarousel, changeCarouselSlide};
};

export default useDesktopHighlightsCarousels;
