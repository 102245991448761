import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import appConfig from '@/config/appConfig';
import storageService from '@/services/storage/storageService';
import {selectIsWelcomeAnimationFinished, selectIsWelcomeAnimationStarted} from '@/state/selectors/global';

const useIsWelcomeMode = () => {
    const isAnimationStarted = useSelector(selectIsWelcomeAnimationStarted);
    const isAnimationFinished = useSelector(selectIsWelcomeAnimationFinished);

    const isWelcomeMode = useMemo(() => {
        const isWelcomeScreenEnabled = appConfig.getIsWelcomeScreenEnabled();
        const hasSeenWelcomeScreen = storageService.getHasSeenWelcomeScreen();

        return isWelcomeScreenEnabled && !hasSeenWelcomeScreen;
    }, []);

    return {isWelcomeMode, isAnimationStarted, isAnimationFinished};
};

export default useIsWelcomeMode;
