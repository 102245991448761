import {useRef} from 'react';

import {VIDEO_PLAY_STATES} from '@/consts/video/videoPlayStates';

// Іf stop time is enabled and video is close to end, it requests frame-by-frame processing (requestAnimationFrame)
// in order to stop right at <stopTime> position and not later, because onTimeUpdate event of <video> html tag does not
// give enough precision
const useGuideVideoStopTime = ({videoRef, stopTime, videoControls}) => {
    const {getVideoEndTime, getVideoPlayState, endVideo} = videoControls;
    const frameRequestIdRef = useRef(null);
    const isStopTimeEnabled = !!stopTime;

    const isFrameRequestInProgress = () => !!frameRequestIdRef.current;

    const stopIfEnded = () => {
        const video = videoRef.current;

        if (video) {
            const endTime = getVideoEndTime(video);
            const isEnded = video.currentTime >= endTime;

            if (isEnded) {
                endVideo();
            } else {
                requestNextFrame();
            }
        }
    };

    const requestNextFrame = () => {
        frameRequestIdRef.current = requestAnimationFrame(stopIfEnded);
    };

    const cancelFrameRequest = () => {
        cancelAnimationFrame(frameRequestIdRef.current);
        frameRequestIdRef.current = null;
    };

    const checkIfCloseToEnd = () => {
        if (isFrameRequestInProgress()) return;

        const video = videoRef.current;

        if (video) {
            const endTime = getVideoEndTime(video);
            const THRESHOLD_IN_SECONDS = 2;
            const isCloseToEnd = video.currentTime + THRESHOLD_IN_SECONDS >= endTime;

            if (isCloseToEnd) {
                requestNextFrame();
            }
        }
    };

    const handlePlay = () => {
        if (isStopTimeEnabled) {
            checkIfCloseToEnd();
        }
    };

    const handlePause = () => {
        if (isStopTimeEnabled) {
            cancelFrameRequest();
        }
    };

    const handleTimeUpdate = () => {
        if (isStopTimeEnabled) {
            if (isFrameRequestInProgress()) return;

            const playState = getVideoPlayState();

            if (playState === VIDEO_PLAY_STATES.PLAYING) {
                checkIfCloseToEnd();
            }
        }
    };

    return {handlePlay, handlePause, handleTimeUpdate};
};

export default useGuideVideoStopTime;
