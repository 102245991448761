import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import WarningBanners from '@/views/WarningBanners/WarningBanners';

import ROUTE_PARAMS from '../../consts/route/routeParams';
import useIsDesktopLayout from '../../hooks/useIsDesktopLayout';
import appRouterService from '../../services/route/appRouterService';
import InboxViewPage from './InboxView/InboxViewPage';
import NotificationDetailsPage from './NotificationDetails/NotificationDetailsPage';

const InboxPage = () => {
    const {[ROUTE_PARAMS.MESSAGE_ID]: messageId} = useParams();
    const isDesktopLayout = useIsDesktopLayout();

    useEffect(() => {
        if (isDesktopLayout) appRouterService.forwardToHomePage();
    }, [isDesktopLayout]);

    return (
        <div className='ica-page w-full'>
            {messageId ? <NotificationDetailsPage messageId={messageId} /> : <InboxViewPage />}
            <WarningBanners source={WarningBanners.SOURCES.INBOX} />
        </div>
    );
};

export default InboxPage;
