import cn from 'classnames';

import {useHomePageHighlightsSectionId} from '../contexts/HomePageHighlightsSectionIdContext';

const HighlightsContainer = ({className, children}) => {
    const sectionId = useHomePageHighlightsSectionId();

    return (
        <section
            id={sectionId}
            className={cn(
                'flex flex-col gap-4 bg-romance px-4 py-8 md:gap-8 md:bg-transparent md:px-16 md:py-6',
                className
            )}
        >
            {children}
        </section>
    );
};

export default HighlightsContainer;
