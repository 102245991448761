import {useState} from 'react';

const useHighlightsCarouselIsVideoBuffering = (highlights) => {
    const defaultIsVideoBuffering = Array(highlights.length).fill(false);
    const [isVideoBufferingList, setIsVideoBufferingList] = useState(defaultIsVideoBuffering);

    const getIsVideoBuffering = (i) => isVideoBufferingList[i];

    const handleVideoWaiting = (i) => {
        const newIsVideoBufferingList = Object.assign([], isVideoBufferingList, {[i]: true});

        setIsVideoBufferingList(newIsVideoBufferingList);
    };

    const handleVideoCanPlay = (i) => {
        const newIsVideoBufferingList = Object.assign([], isVideoBufferingList, {[i]: false});

        setIsVideoBufferingList(newIsVideoBufferingList);
    };

    return {getIsVideoBuffering, handleVideoWaiting, handleVideoCanPlay};
};

export default useHighlightsCarouselIsVideoBuffering;
