import React, {useState} from 'react';

import {PopupFullPage} from '@/components/Popup';
import appConfig from '@/config/appConfig';
import {useHomePageHighlightsSectionId} from '@/containers/HomePage/components/HomePageHighlights/contexts/HomePageHighlightsSectionIdContext';
import useScrollToPreviousPosition from '@/hooks/useScrollToPreviousPosition';
import amplitudeHomePageTracking from '@/services/amplitude/amplitudeHomePageTracking';
import {filterSectionsById} from '@/services/pageSectionService';

import HighlightsCarousel from '../HighlightsCarousel';
import HighlightsContainer from '../HighlightsContainer';
import HighlightsHeader from '../HighlightsHeader';

const getHighlights = (id) => {
    const highlightsFromConfig = appConfig.getHighlights();
    const highlights = filterSectionsById({list: highlightsFromConfig, id});

    return highlights?.sort(({banner: a}, {banner: b}) => a?.order - b?.order);
};

const MobileHighlights = ({className}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const id = useHomePageHighlightsSectionId();

    const trackBannerClick = () => {
        const currentHighlight = highlights[currentIndex];

        if (!currentHighlight) {
            return null;
        }

        const trackingValue = currentHighlight.banner?.amplitudeTrackingValue;

        amplitudeHomePageTracking.trackHighlightBannerClick(trackingValue);
    };

    const onSlideClick = () => {
        trackBannerClick();
        setIsFullscreen(true);
    };
    const onSlideChange = (index) => {
        setCurrentIndex(index);
    };
    const highlights = getHighlights(id);
    const highlightsAutoplay = appConfig.getHighlightsAutoplay();

    if (!highlights?.length) return;

    return (
        <HighlightsContainer className={className}>
            {isFullscreen ? (
                <HighlightsCarouselFullscreen
                    setIsFullscreen={setIsFullscreen}
                    currentIndex={currentIndex}
                    highlights={highlights}
                    autoplay={highlightsAutoplay}
                    onSlideChange={onSlideChange}
                    isInfinite
                />
            ) : (
                <>
                    <HighlightsHeader />
                    <HighlightsCarousel
                        initialSlide={currentIndex}
                        currentIndex={currentIndex}
                        onSlideClick={onSlideClick}
                        highlights={highlights}
                        autoplay={highlightsAutoplay}
                        onSlideChange={onSlideChange}
                        isInfinite
                    />
                </>
            )}
        </HighlightsContainer>
    );
};

const HighlightsCarouselFullscreen = ({
    setIsFullscreen,
    currentIndex,
    highlights,
    autoplay,
    onSlideChange,
    isInfinite,
}) => {
    useScrollToPreviousPosition();

    return (
        <PopupFullPage
            className='z-10 bg-romance'
            isCloseVisible
            onClose={() => setIsFullscreen(false)}
            closeButtonClass='text-white top-safe-offset-2 right-2'
        >
            <HighlightsCarousel
                initialSlide={currentIndex}
                currentIndex={currentIndex}
                isFullscreen
                highlights={highlights}
                autoplay={autoplay}
                onSlideChange={onSlideChange}
                isInfinite={isInfinite}
            />
        </PopupFullPage>
    );
};

export default MobileHighlights;
