import React from 'react';

import {twx} from '@/utils/tailwindUtils';

const FullscreenHighlightsAnimatedContainer = ({children, isBigger, hasOverlay, onOverlayClick}) => {
    return (
        <div
            className={twx(
                'relative h-[80%] w-[calc(100%-64px)] overflow-hidden rounded-3xl',
                isBigger && 'h-full w-full',
                'transition-all duration-[400ms] ease-[ease]'
            )}
        >
            <InvisibleOverlay isPresent={hasOverlay} onClick={onOverlayClick} />
            {children}
        </div>
    );
};

const InvisibleOverlay = ({isPresent, onClick}) => {
    return (
        <div className={twx('absolute left-0 top-0 z-20 h-full w-full', !isPresent && 'hidden')} onClick={onClick} />
    );
};

export default FullscreenHighlightsAnimatedContainer;
