import cn from 'classnames';
import React from 'react';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {ButtonRaw} from '../../../../components/Button';
import {twx} from '../../../../utils/tailwindUtils';

const SidebarMenuSection = ({headerItemName, className, items, activeItemKey}) => {
    return (
        <article className={twx('mt-8', className)}>
            <SidebarMenuSectionHeader itemName={headerItemName} />
            <ul className='mt-4 flex flex-col gap-2'>
                {items.map((item, index) => {
                    return <SidebarMenuSectionListItem key={index} activeItemKey={activeItemKey} {...item} />;
                })}
            </ul>
        </article>
    );
};

const SidebarMenuSectionHeader = ({itemName}) => {
    return (
        <h2 className='mb-0 px-4 text-16'>
            <AemEditableText pageName='profile' itemName={itemName} />
        </h2>
    );
};

const SidebarMenuSectionListItem = ({icon, itemName, onClick, itemKey, activeItemKey, disabled}) => {
    return (
        <li>
            <ButtonRaw
                className={cn('flex w-full gap-4 p-4 text-14 leading-[20px]', {
                    'rounded-full bg-tertiary-30': itemKey && activeItemKey === itemKey,
                    'opacity-40': disabled,
                    'py-2': icon,
                })}
                onClick={onClick}
                disabled={disabled}
            >
                {icon && icon}
                <span className='line-clamp-2 flex-1 break-words'>
                    <AemEditableText pageName='profile' itemName={itemName} />
                </span>
            </ButtonRaw>
        </li>
    );
};

export default SidebarMenuSection;
