import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {ArrowSmallIcon} from '@/components/Icons';
import {twx} from '@/utils/tailwindUtils';

const FullscreenHighlightsArrowButton = ({isFlipped, onClick}) => {
    return (
        <ButtonRaw
            className={twx(
                'flex-center absolute left-auto right-[calc(100%/3-56px)] top-1/2 z-10 flex h-10 w-10 shrink-0 -translate-y-1/2 rounded-full bg-romance text-primary',
                isFlipped && 'rotate-y-180 left-[calc(100%/3-56px)] right-auto'
            )}
            onClick={onClick}
        >
            <ArrowSmallIcon />
        </ButtonRaw>
    );
};

export default FullscreenHighlightsArrowButton;
