import cn from 'classnames';
import React from 'react';

const InlineProgress = ({progress, className, progressClassName, progressStyle = {}}) => {
    const style = {width: `${progress}%`, ...progressStyle};

    return (
        <div className={cn('relative h-3 overflow-hidden rounded-3xl', className)}>
            <div className={cn('absolute left-0 top-0 h-full', progressClassName)} style={style} />
        </div>
    );
};

export default InlineProgress;
