import {useSelector} from 'react-redux';

import BackButton from '../../../../components/BackButton/BackButton';
import {PreferenceCenterIcon} from '../../../../components/Icons';
import {TERMS_OF_USE, WEB_PREFERENCE_CENTER} from '../../../../consts/tnc/tncTypes';
import {selectIsAllMandatoryTermsApproved} from '../../../../state/selectors/consumerAggregatedSettings';
import SidebarMenuSection from './SidebarMenuSection';

const TncSidebar = ({
    onPreferenceCenterClick,
    onTermsOfUseClick,
    onPrivacyNoticeClick,
    onAttributionNoticeClick,
    pageType,
}) => {
    const isAllMandatoryTermsApproved = useSelector(selectIsAllMandatoryTermsApproved);

    return (
        <section className='w-full p-8 text-start'>
            {isAllMandatoryTermsApproved && <BackButton />}
            <SidebarMenuSection
                headerItemName='accountSettingsHeader'
                items={[
                    {
                        icon: <PreferenceCenterIcon className='shrink-0' />,
                        itemName: 'preferenceCentreButton',
                        onClick: onPreferenceCenterClick,
                        itemKey: WEB_PREFERENCE_CENTER,
                        disabled: !isAllMandatoryTermsApproved,
                    },
                ]}
                activeItemKey={pageType}
            />
            <SidebarMenuSection
                headerItemName='legalHeader'
                items={[
                    {
                        itemName: 'termsOfUseButton',
                        onClick: onTermsOfUseClick,
                        itemKey: TERMS_OF_USE,
                    },
                    {
                        itemName: 'privacyNoticeButton',
                        onClick: onPrivacyNoticeClick,
                    },
                    {
                        itemName: 'attributionNoticeButton',
                        onClick: onAttributionNoticeClick,
                    },
                ]}
                activeItemKey={pageType}
            />
        </section>
    );
};

export default TncSidebar;
