import amplitudeEventProperties from '@/consts/amplitude/amplitudeEventProperties';
import {AMPLITUDE_PLUGIN_TYPES} from '@/consts/amplitude/amplitudePluginTypes';
import marketService from '@/services/marketService';

class EnricherPlugin {
    name = 'enricher-plugin';
    type = AMPLITUDE_PLUGIN_TYPES.ENRICHMENT;

    async setup(config) {
        return undefined;
    }

    async execute(event) {
        const eventProperties = event[amplitudeEventProperties.EVENT_PROPERTIES] || {};

        event[amplitudeEventProperties.EVENT_PROPERTIES] = {
            ...eventProperties,
            market: marketService.getMarketCode()?.toUpperCase(),
        };

        return event;
    }
}

export default EnricherPlugin;
