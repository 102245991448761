import useIsMobileLayout from '@/hooks/useIsMobileLayout';

import DesktopHighlights from './components/DesktopHighlights/DesktopHighlights';
import MobileHighlights from './components/MobileHighlights/MobileHighlights';
import {HomePageHighlightsSectionIdContext} from './contexts/HomePageHighlightsSectionIdContext';

const HomePageHighlights = ({id, ...props}) => {
    const isMobile = useIsMobileLayout();

    return (
        <HomePageHighlightsSectionIdContext.Provider value={id}>
            {isMobile ? <MobileHighlights {...props} /> : <DesktopHighlights {...props} />}
        </HomePageHighlightsSectionIdContext.Provider>
    );
};

export default HomePageHighlights;
