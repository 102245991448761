import cn from 'classnames';
import React from 'react';

import {ButtonRaw} from '@/components/Button';
import appConfig from '@/config/appConfig';
import SignedImage from '@/containers/System/SignedImage';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import usePageSectionTitle from '@/hooks/usePageSectionTitle';
import amplitudeShopPageTracking from '@/services/amplitude/amplitudeShopPageTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {filterSectionsById} from '@/services/pageSectionService';
import arrayUtils from '@/utils/arrayUtils';

const getProducts = (id) => {
    const productsFromConfig = appConfig.getShopByProductSection();
    const products = filterSectionsById({list: productsFromConfig, id});
    const productsWithAction = products?.filter((p) => p?.action);

    if (!productsWithAction?.length) return null;

    const productsWithOrder = arrayUtils.sortArrayWithOrderField(
        productsWithAction?.filter((p) => p?.order !== undefined)
    );
    const productsWithoutOrder = productsWithAction.filter((p) => p?.order === undefined);

    return [...productsWithOrder, ...productsWithoutOrder];
};

const ShopByProductSection = ({id, className}) => {
    const products = getProducts(id);
    const localizedStrings = getLocalizedStrings();
    const titleText = usePageSectionTitle(id);

    if (!products?.length) return;

    return (
        <section className={cn('bg-romance px-4 py-8 md:bg-transparent', className)}>
            <h2 className='mb-0 text-24'>{titleText}</h2>
            <div className='mt-4 grid grid-cols-2 gap-x-2 gap-y-4 md:grid-cols-4 lg:flex lg:flex-wrap'>
                {products.map(({imagePath, titleKey, action, amplitudeTrackingValue}, index) => (
                    <ShopByProductTile
                        imagePath={imagePath}
                        title={localizedStrings[titleKey]}
                        action={action}
                        amplitudeTrackingValue={amplitudeTrackingValue}
                        key={index}
                    />
                ))}
            </div>
        </section>
    );
};

const ShopByProductTile = ({imagePath, title, action, amplitudeTrackingValue}) => {
    const combinedImagePath = appConfig.getCombinedPathAssetPath(imagePath);
    const redirectToExternalLink = useConfigExternalLink(action);
    const onTileClick = () => {
        amplitudeShopPageTracking.trackShopByProductBannerClick(amplitudeTrackingValue);
        redirectToExternalLink();
    };

    return (
        <ButtonRaw
            className='flex max-w-[250px] flex-col items-start gap-4 rounded-xl bg-secondary p-4 md:bg-romance lg:w-full'
            onClick={action && onTileClick}
        >
            <span className='line-clamp-2 w-full text-14 font-bold leading-[20px] text-black'>{title}</span>
            <div className='aspect-square w-full'>
                <SignedImage imgClassName='h-full object-contain' mediaData={combinedImagePath} signIfNull />
            </div>
        </ButtonRaw>
    );
};

export default ShopByProductSection;
