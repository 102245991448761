import cn from 'classnames';
import React, {forwardRef} from 'react';

const HighlightsCarouselButton = forwardRef(function HighlightsCarouselButton(
    {animationClass, animationDuration, isAnimationPlaying, isAnimationEnded, onAnimationEnd, ...props},
    ref
) {
    return (
        <div className='h-1 w-full cursor-pointer overflow-hidden rounded-lg bg-white bg-opacity-50' {...props}>
            <span
                ref={ref}
                onAnimationEnd={onAnimationEnd}
                className={cn('block h-full bg-white', {
                    'w-full': isAnimationEnded,
                    [animationClass]: !!animationDuration,
                    paused: !isAnimationPlaying,
                })}
                style={{animationDuration: `${animationDuration}s`}}
            />
        </div>
    );
});

const HighlightsCarouselButtonContainer = ({children, isFullscreen, ...props}) => {
    return (
        <div {...props}>
            <div
                className={cn(
                    'absolute left-0 right-0 z-10',
                    isFullscreen ? 'top-safe-offset-14' : 'top-10 md:top-[50px]'
                )}
            >
                <ul className='flex gap-2 px-4 md:px-6'>{children}</ul>
            </div>
        </div>
    );
};

HighlightsCarouselButton.Container = HighlightsCarouselButtonContainer;

export default HighlightsCarouselButton;
