import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isAem: false,
    isAppLoading: true,
    isConfigInitialized: false,
    errorPageType: undefined,
    languageCode: undefined,
    swMessage: undefined,
    hwContainerIds: [],
    isWelcomeAnimationStarted: false,
    isWelcomeAnimationFinished: false,
};

export const slice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setLayout: (state, action) => {
            state.layout = action.payload;

            return state;
        },
        setIsAem: (state, action) => {
            state.isAem = action.payload;

            return state;
        },
        setIsAppLoaded: (state, action) => {
            state.isAppLoading = action.payload;

            return state;
        },
        setIsConfigInitialized: (state, action) => {
            state.isConfigInitialized = action.payload;

            return state;
        },
        logoutClearData: (state, action) => {
            return state;
        },
        setLanguageCode: (state, action) => {
            state.languageCode = action.payload;

            return state;
        },
        setSwMessage: (state, action) => {
            state.swMessage = action.payload;

            return state;
        },
        clearSwMessage: (state, action) => {
            state.swMessage = undefined;

            return state;
        },
        setHwContainerId: (state, action) => {
            state.hwContainerIds.push(action.payload);

            return state;
        },
        clearHwContainerId: (state, action) => {
            state.hwContainerIds = state.hwContainerIds.filter((id) => id !== action.payload);

            return state;
        },
        setIsWelcomeAnimationStarted: (state, action) => {
            state.isWelcomeAnimationStarted = action.payload;

            return state;
        },
        setIsWelcomeAnimationFinished: (state, action) => {
            state.isWelcomeAnimationFinished = action.payload;

            return state;
        },
    },
});

export const {
    setLayout,
    setIsAem,
    setIsAppLoaded,
    setIsConfigInitialized,
    logoutClearData,
    setLanguageCode,
    setSwMessage,
    clearSwMessage,
    setHwContainerId,
    clearHwContainerId,
    setIsWelcomeAnimationStarted,
    setIsWelcomeAnimationFinished,
} = slice.actions;

export default slice.reducer;
