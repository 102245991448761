import React, {useState} from 'react';

import {twx} from '@/utils/tailwindUtils';

import HighlightsContainer from '../../components/HighlightsContainer';
import {useHomePageHighlightsSectionId} from '../../contexts/HomePageHighlightsSectionIdContext';
import HighlightsHeader from '../HighlightsHeader';
import DesktopHighlightsCarouselList from './components/DesktopHighlightsCarouselList';
import FullscreenDesktopHighlights from './components/FullscreenDesktopHighlights/FullscreenDesktopHighlights';
import {getDesktopHighlights} from './utils/desktopHighlightsUtils';

const DesktopHighlights = ({className}) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const id = useHomePageHighlightsSectionId();
    const {carouselsFromConfig, isAutoPlayEnabled} = getDesktopHighlights(id);

    if (!carouselsFromConfig?.length) return null;

    return (
        <HighlightsContainer className={twx(className, '@container')}>
            <HighlightsHeader onViewAllClick={() => setIsFullscreen(true)} />
            <DesktopHighlightsCarouselList
                carouselsFromConfig={carouselsFromConfig}
                isAutoPlayEnabled={isAutoPlayEnabled}
            />
            {isFullscreen && (
                <FullscreenDesktopHighlights
                    carouselsFromConfig={carouselsFromConfig}
                    isAutoPlayEnabled={isAutoPlayEnabled}
                    onClose={() => setIsFullscreen(false)}
                />
            )}
        </HighlightsContainer>
    );
};

export default DesktopHighlights;
