import React, {useState} from 'react';

import WarningBanners from '@/views/WarningBanners/WarningBanners';

import Container from '../../components/Layout/Container/Container';
import appService from '../../services/appService';
import authService from '../../services/auth/authService';
import log from '../../services/logger/log';
import pushNotificationService from '../../services/pushNotificationService';
import {hideLoader, showLoader} from '../../state/slices/loadersSlice';
import {dispatch} from '../../state/store';
import LoginForm from './components/LoginForm/LoginForm';
import LoginHeader from './components/LoginHeader';

const LoginPage = () => {
    const [isLoginError, setIsLoginError] = useState(false);

    const onLogin = async (credentials) => {
        setIsLoginError(false);
        dispatch(showLoader());

        try {
            await authService.login(credentials);
            await appService.fetchConsumerData();
            await pushNotificationService.checkSubscriptionInfoForNewUser();
        } catch (e) {
            setIsLoginError(true);
            log.error(`LoginPage: standaloneLogin, ${e}`);
        }

        dispatch(hideLoader());
    };

    return (
        <div className='flex w-full'>
            <Container className='bg-romance lg:mb-auto lg:mt-10 lg:h-auto'>
                <LoginHeader />
                <LoginForm onLogin={onLogin} isLoginError={isLoginError} setIsLoginError={setIsLoginError} />
                <WarningBanners source={WarningBanners.SOURCES.LOGIN} />
            </Container>
        </div>
    );
};

export default LoginPage;
