import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import StickyHeader from '@/components/Layout/StickyHeader';
import PopupResponsive from '@/components/Popup/PopupResponsive';
import {TNC_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useIsMobileLayout from '@/hooks/useIsMobileLayout';
import useTermsOfUsePopup from '@/hooks/useTermsOfUsePopup';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import tncService from '@/services/tncService';
import {selectTncLegals} from '@/state/selectors/consumer';
import {selectTncPopupList} from '@/state/selectors/tnc';
import {removeLastTncPopup} from '@/state/slices/tncSlice';
import {twx} from '@/utils/tailwindUtils';
import WarningBanners from '@/views/WarningBanners/WarningBanners';

import TermsOfUseDescription from '../TermsOfUseDescription/TermsOfUseDescription';

const TermsOfUsePopupView = () => {
    const [popupRef, setPopupRef] = useState();
    const isMobileLayout = useIsMobileLayout();
    const legals = useSelector(selectTncLegals);
    const tncPopupsList = useSelector(selectTncPopupList);
    const dispatch = useDispatch();

    const {code, className, overlayClassName} = tncPopupsList.slice(-1)[0];
    const handleClose = () => dispatch(removeLastTncPopup());
    const {openPopup} = useTermsOfUsePopup({overlayClassName});

    const localizedStrings = getLocalizedStrings();
    const legalData = tncService.getTncLegalByTermsCode(legals, code);

    if (!legalData) {
        dispatch(removeLastTncPopup());
        return;
    }
    const {text, title} = legalData;

    return (
        <PopupResponsive
            className={twx(
                'overflow-auto bg-romance md:max-h-[calc(100vh-64px)] md:w-[calc(100%-theme(spacing.16))] md:max-w-[952px] md:rounded-3xl md:p-0',
                className
            )}
            overlayClassName={overlayClassName}
            isCloseVisible={!isMobileLayout}
            onClose={handleClose}
            contentRef={setPopupRef}
        >
            <div className='px-4 pb-4 pt-safe-offset-14 md:px-8 md:pb-8 md:pt-22' key={code}>
                <StickyHeader
                    onBack={handleClose}
                    title={localizedStrings[TNC_PAGE_HEADER_TITLE]}
                    scrollElement={popupRef}
                />
                <h1 className='mb-4 text-center text-24 md:mb-8 md:text-start md:text-32 md:font-bold md:leading-[42px]'>
                    {title}
                </h1>
                <TermsOfUseDescription
                    innerHtml={text}
                    className='mt-4 break-words text-justify [&_a]:bg-none [&_span]:text-inherit'
                    onTncLinkClick={openPopup}
                />
            </div>
            {isMobileLayout && <WarningBanners source={WarningBanners.SOURCES.TNC_DETAILS} />}
        </PopupResponsive>
    );
};

export default TermsOfUsePopupView;
