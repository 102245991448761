import appConfig from '@/config/appConfig';
import {filterSectionsById} from '@/services/pageSectionService';
import arrayUtils from '@/utils/arrayUtils';

const getDesktopHighlights = (id) => {
    const carouselsFromConfig = appConfig.getHighlightsDesktop();
    const isAutoPlayEnabled = appConfig.getHighlightsDesktopAutoplay();

    const sortHighlightsInCarousel = ({highlights, ...rest}) => {
        const byHighlightOrder = ({banner: a}, {banner: b}) => a?.order - b?.order;

        const filteredHighlights = filterSectionsById({list: highlights, id});
        const sortedHighlights = filteredHighlights?.sort(byHighlightOrder);

        if (!sortedHighlights?.length) return;

        const carousel = {
            ...rest,
            highlights: sortedHighlights,
        };

        return carousel;
    };

    const byCardOrder = ({order: a}, {order: b}) => a - b;

    const sortedCarouselsFromConfig = arrayUtils.filterEmptyObjects(
        carouselsFromConfig?.map(sortHighlightsInCarousel)?.sort(byCardOrder)
    );

    return {
        carouselsFromConfig: sortedCarouselsFromConfig || [],
        isAutoPlayEnabled,
    };
};

export {getDesktopHighlights};
