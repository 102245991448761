export const WARNING_BANNER_SOURCE_TYPES = {
    HOME: '/home',
    SHOP: '/shop',
    DEVICES: '/devices',
    LOGIN: '/login',
    DEVICE_RENAME: '/device-rename',
    AD: '/ad',
    CLEAN_TUTORIAL: '/clean-tutorial',
    SELECT_DEVICE: '/select-device',
    INSTALL_PWA: '/install-pwa',
    PROFILE: '/profile',
    BROWSER_NOT_COMPATIBLE_POPUP: '/browser-not-compatible-popup',
    LEVIA_POPUP: '/levia-popup',
    FIRMWARE_AVAILABLE_POPUP: '/firmware-available-popup',
    CONTACT_US: '/contact-us',
    TIPS_AND_TUTORIALS: '/tips-and-tutorials',
    INBOX: '/inbox',
    AUTO_DELIVERY: '/auto-delivery',
    PREFERENCE_CENTER: '/preference-center',
    TNC_DETAILS: '/tnc-details',
    TNC: '/tnc',
    ABOUT_DEVICE: '/about-device',
};
