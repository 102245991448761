import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import Banner from '@/components/Banner/Banner';
import usePageSectionBanner from '@/hooks/usePageSectionBanner';
import usePageSectionTitle from '@/hooks/usePageSectionTitle';
import amplitudeShopPageTracking from '@/services/amplitude/amplitudeShopPageTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import {selectIsAem} from '@/state/selectors/global';

const YourUsualSection = ({id, className}) => {
    const titleText = usePageSectionTitle(id);
    const banner = usePageSectionBanner(id);
    const {imagePath, videoPath, isGradient, titleKey, descriptionKey, isDarkBannerText} = banner || {};
    const localizedStrings = getLocalizedStrings();
    const isAem = useSelector(selectIsAem);
    const isBannerDataExist = imagePath || videoPath || titleKey || descriptionKey;
    const onBannerClick = () => {
        amplitudeShopPageTracking.trackQuickOrderBannerClick();
        appRouterService.forwardToQuickOrderPage();
    };

    return (
        (isAem || isBannerDataExist) && (
            <article className={cn('bg-romance px-4 py-8 md:bg-transparent', className)}>
                <h2 className='text-24'>{titleText}</h2>
                <Banner
                    className='h-60 cursor-pointer overflow-hidden rounded-xl bg-tertiary text-romance'
                    imagePath={imagePath}
                    videoPath={videoPath}
                    isGradient={isGradient}
                    isDarkBannerText={isDarkBannerText}
                    gradient='linear-gradient(180deg, rgba(52, 48, 61, 0) 39.99%, #1C1A21 100%)'
                    onClick={onBannerClick}
                >
                    <div className='relative flex h-full flex-col justify-end p-4'>
                        <h2 className='mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-24'>
                            {localizedStrings[titleKey]}
                        </h2>
                        <p className='line-clamp-2 whitespace-pre-line'>{localizedStrings[descriptionKey]}</p>
                    </div>
                </Banner>
            </article>
        )
    );
};

export default YourUsualSection;
